<template>
  <div class="home">
    <img @click="popup" @mouseover="hoverPopupImage" @mouseleave="normalPopupImage" :src="popupImage"
      class="list-icon-click" alt="">
    <section v-if="loadingPCShow" class="come-in-loading">
      <!-- <img class="loading-img" src="@/assets/img/top_logo.png" alt=""> -->
      <!-- <video autoplay="autoplay" loop="loop" muted class="loading-video" src="@/assets/video/loading-play.mp4"></video> -->
      <img class="loading-img-gif" src="@/assets/img/fristLoading_800_new.gif" alt="">
      <!-- <img class="loading-img" :alt="currentIndex" :src="gif" /> -->
    </section>

    <div class="top-text-language-wrap">
      <span @click="selectLanguage('1')"
          :class="['top-text-language-select-li-span-en', languageTypeActive === '1' ? 'top-text-language-select-li-span-active' : '']">EN</span>
        <span style="opacity:0.5;">&nbsp;/&nbsp;</span>
        <span @click="selectLanguage('2')"
          :class="['top-text-language-select-li-span-chinese', languageTypeActive !== '1' ? 'top-text-language-select-li-span-active' : '']">中</span>
    </div>
    <section id="topID" class="top">
      
      <div class='hero'>
        
        <div class='layer-bg layer parallax' data-depth='0.50'>
          <video id="topVideoID" ref="topVideo" class="layer-bg-video"
            x5-video-player-type='h5'
            x5-video-orientation='portrait'
            mtt-playsinline="true"
            playsinline
            webkit-playsinline='true'
            preload="auto" muted src="../assets/video/top-bg-new-ys-new.mp4"></video>
        </div>
        <!-- <div class='layer-1 layer parallax' data-depth='0.50'>
          <img class="layer-top-center-img" src="@/assets/img/top_center.png" alt="">
          <img class="layer-top-left-renson-img" src="@/assets/img/top_left_renson.png" alt="">
        </div> -->
        <!-- <div class='layer parallax-gif' data-depth='0.10'>
          <img class="layer-top01-img" src="@/assets/img/top01_1920.gif" alt="">
        </div> -->
        <div class='layer parallax' data-depth='0.60'>
          <div v-if="languageTypeActive !== '1'" class="top-text-img-wrap">
            <div class="top-text-en-wrap">
              <img class="layer-logo-img" src="@/assets/img/top_logo.png" alt="">
              <img class="layer-text-en" src="@/assets/img/top_text_en.png" alt="">
            </div>
            <div class="layer-text-chinese">
              <div class="layer-text-chinese-title1">【动画短片】 </div>
              <div style="text-align:right;">
                <p class="layer-text-chinese-title2">得不了动画出品<br/>导演：西风</p>
              </div>
            </div>
          </div>
          <div v-else class="top-text-img-wrap-en">
            <div class="top-text-en-wrap-1">
              <img class="layer-text-en-1" src="@/assets/img/top_text_en_1.png" alt="">
              <img class="layer-logo-img-1" src="@/assets/img/top_logo_1.png" alt="">
            </div>
            <div class="layer-text-chinese">
              <div class="layer-text-chinese-title1-1">A theatrical animated short film</div>
              <div style="text-align:right;">
                <p class="layer-text-chinese-title2-1">Presented by Debris Studio<br/>&nbsp;&nbsp;Directed by Xi Feng</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="textID" class="text">
      <img class="textID-top-bottom-img" src="@/assets/img/top_bottom.png" alt="">
      <div class="text-box">
        <p :class="['text-box-p', languageTypeActive == '1' ? 'mb47' : 'text-box-p-chinese','mb45']" >
        </p>
        <p :class="['text-box-p', languageTypeActive == '1' ? 'mb52' : 'text-box-p-chinese','mb59']" >
        </p>
        <div class="text-box2">
          <p :class="['text-box-p text-box-p2', languageTypeActive == '1' ? 'mb26' : 'text-box-p-chinese','text-box-p-chinese2','mb30']"
            >
          </p>
          <p :class="['text-box-p text-box-p2', languageTypeActive == '1' ?  'mb26' : 'text-box-p-chinese','text-box-p-chinese2','mb30']"
          >
          </p>
          <p :class="['text-box-p text-box-p2 mb16',  languageTypeActive == '1' ? '' : 'text-box-p-chinese','text-box-p-chinese2']">
          </p>
          <div class="text-boxIcon">
            <p :class="['text-box-p text-box-p2', languageTypeActive == '1' ? '' : 'text-box-p-chinese','text-box-p-chinese2']">
            </p>
            <div class="text-box-icon"></div>
          </div>
        </div>

      </div>

    </section>
    <section  class="OVAs">
      <div class="OVAs-top">
        <img class="OVAs-top-img move-right" loading="lazy" src="../assets/img/headstock-img.png">
        <img class="OVAs-top-img1 move-left" loading="lazy" src="../assets/img/tailstock-img.png">
      </div>
      <img class="grass-img move-right" loading="lazy" src="../assets/img/grass_img.png">
      <img class="ship-img move-left" loading="lazy" src="../assets/img/ship_img.png">
      
      <div class="OVAs-center">
        <span id="OVAsID" style="position: absolute;top:-30rem;left:50%;display:inline-block;width:1rem;height:1rem;opacity: 0;"></span>
        <div class="video-box">
          <div class="video-border"></div>
          <div class="video-err" v-if="videoError">
            <p>Video failed to load. Please check your network connection.</p>
            <div class="videoerr-ImgBtn" @click="reloadVideo">
              <img src="../assets/icon/refresh_icon.png" alt="">
              <span>Refresh</span>
            </div>
          </div>
          <div class="cover_bg" v-if="isPlaying">
            <img @click="togglePlay" src="../assets/img/play_btn.png" alt="">
          </div>
          <video class="video" autoplay @error="handleError" @play="handlePlay" @pause="handlePause" ref="videoRef"
            webkit-playsinlin="true" preload="metadata" loop="loop" :muted="isMuted"
            src="../assets/video/yr.mp4"></video>
          <!-- <img id="playPauseButton" v-if="isPlaying" class="play-btn" @click="togglePlay" src="../assets/img/play.png"
            alt=""> -->
          <!-- <div @click="toggleMute" class="muted-icon">
            <img v-show="isMuted" src="../assets/icon/muted_icon.png" alt="">
            <img v-show="!isMuted" src="../assets/icon/muted_icon_active.png" alt="">
          </div> -->
        </div>
      </div>
    </section>
    <section id="characterID" class="character">
      <div class="shade"></div>
      <div class="shade2"></div>
      <div class="character-title">
        <h2 :class="{'mb17':true, 'character-title-chinese':languageTypeActive != '1'}">{{ languageTypeActive !== '1' ? '角色介绍' : 'CHARACTERS' }}</h2>
        <img src="../assets/icon/line_icon.png" alt="">
      </div>
      <div class="character-box">
        <div class="role-box" ref="roleBox">
          <ul class="role-box-ul">
            <li class="role-box-li">
              <div class="silhouette">
                <img src="../assets/img/laoge_img.png" alt="">
                <div class="silhouette_boot"></div>
              </div>
              <span>{{ languageTypeActive !== '1' ? '葛' : 'Ge' }}</span>
            </li>
            <li class="role-box-li">
              <div class="silhouette silhouette2">
                <img src="../assets/img/xiaotangren_img.png" alt="">
                <div class="silhouette_boot"></div>
              </div>
              <span class="pl20">{{ languageTypeActive !== '1' ? '唐' : 'Tang' }}</span>
            </li>
            <li class="role-box-li">
              <div class="silhouette silhouette3">
                <img src="../assets/img/tuoniao_img.png" alt="">
                <div class="silhouette_boot"></div>
              </div>
              <span>{{ languageTypeActive !== '1' ? '呆鸟' : 'Dodo' }}</span>
            </li>
          </ul>
          <div class="role-box-describe" ref="boxDescribe">
            <div class="describe-centre" v-show="roleNum == 0">
              <div class="role-box-describe-bg"></div>
              <div class="describe-centre-btn"></div>
              <img class="skin_figure" src="../assets/img/laoge2_img.png" alt="">
              <div  :class="['introduce', languageTypeActive === '1' ? '' :'introduce-chinese']">
                <h2 class="introduce_text_name">{{ languageTypeActive !== '1' ? '葛' : 'Ge' }}</h2>
                <h4 class="introduce_text">
                  {{ languageTypeActive !== '1' ? ge_cn : ge_en }}
                </h4>
              </div>
            </div>
            <div class="describe-centre" v-show="roleNum == 1">
              <div class="role-box-describe-bg  describe-bg2"></div>
              <div class="describe-centre-btn"></div>
              <img class="skin_figure skin2" src="../assets/img/xiaotangren2_img.png" alt="">
              <div  :class="['introduce introduce2', languageTypeActive === '1' ? '' :'introduce-chinese']" >
                <h2 class="introduce_text_name">{{ languageTypeActive !== '1' ? '唐' : 'Tang' }}</h2>
                <h4 class="introduce_text"> {{ languageTypeActive !== '1' ? tang_cn : tang_en }}</h4>
              </div>
            </div>
            <div class="describe-centre" v-show="roleNum == 2">
              <div class="role-box-describe-bg  describe-bg3"></div>
              <div class="describe-centre-btn"></div>
              <img class="skin_figure skin3 " src="../assets/img/tuoniao2_img.png" alt="">
              <div :class="['introduce introduce3', languageTypeActive === '1' ? '' :'introduce-chinese']">
                <h2 class="introduce_text_name">{{ languageTypeActive !== '1' ? '呆鸟' : 'Dodo' }}</h2>
                <h4 class="introduce_text">{{ languageTypeActive !== '1' ? niao_cn : niao_en }}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="character-box-bottom"></div>
    </section>
    <section id="rollID" class="roll">
      <div class="rollID-height"></div>
      <div class="rollID-title">
        <h2 class="title-h2">{{ languageTypeActive !== '1' ? '概念设定' : 'CONCEPT ART' }}</h2>
        <img src="../assets/icon/rollID_title_icon.png" alt="">
      </div>
      <div class="roll-box">
        <ul class="roll-box-ul wrapper">
          <li class="roll-box-li"><img @click="showImage(rollImage3)" width='786rem' height="442rem" class="roll-box-img"
              :src="rollImage3" alt=""></li>
          <li class="roll-box-li"><img @click="showImage(rollImage2)" width='786rem' height="442rem" class="roll-box-img"
              :src="rollImage2" alt=""></li>
          <li class="roll-box-li"><img @click="showImage(rollImage1)" width='786rem' height="442rem" class="roll-box-img"
              :src="rollImage1" alt=""></li>
        </ul>
      </div>
      <div class="roll-box">
        <ul class="roll-box-ul wrapper">
          <li class="roll-box-li"><img @click="showImage(rollImage4)" width='786rem' height="786rem" class="roll-box-img"
              :src="rollImage4" alt=""></li>
          <li class="roll-box-li"><img @click="showImage(rollImage5)" width='786rem' height="786rem" class="roll-box-img"
              :src="rollImage5" alt=""></li>
          <li class="roll-box-li"><img @click="showImage(rollImage6)" width='786rem' height="442rem" class="roll-box-img"
              :src="rollImage6" alt=""></li>
        </ul>
      </div>
    </section>
    <section class="bottom">
      <div class="footer-box">
        <div class="footer-box-top">
          <div class="footer-box-topLeft" @click="popupContact">
            <p >{{ languageTypeActive !== '1' ? '联系我们' : 'CONTACT US'
              }}</p>
            <div class="topLeft-iconbox" ref="iconbox">
              <img class="topLeft-icon" src="../assets/img/arrows_icon.png" alt="">
            </div>
          </div>
          <div class="footer-logo" @click="goUrl">
            <span>{{ languageTypeActive !== '1' ? '得不了动画工作室' : 'Produced by Debris Studio' }}</span>
            <div class="logoImg"></div>
            <div class="topLeft-iconbox" ref="iconbox">
              <img class="topLeft-icon2" src="../assets/img/arrows_icon.png" alt="">
            </div>
          </div>
        </div>
        <img class="partition-img" src="../assets/img/Bottom_line_img.png" alt="">
        <div class="footer-box-bottom">
          <!-- <div class="footer-box-bottomIcon">
            <img
              :src="footeryoutubeType ? require('../assets/img/youtube_icon.png') : require('../assets/icon/youtube_footer_icon.png')"
              @mouseover="footeryoutubeType = false" @mouseleave="footeryoutubeType = true" class="icon" alt="">
            <img
              :src="footerFacebookType ? require('../assets/img/facebook_icon.png') : require('../assets/icon/facebook_footer_icon.png')"
              @mouseover="footerFacebookType = false" @mouseleave="footerFacebookType = true" class="icon" alt="">
          </div> -->
          <p>Copyright © 2024 Debris Studio. All rights reserved.</p>
        </div>
      </div>
    </section>

    <!-- ******弹窗放在最后****** -->
    <section class="popup-id popup">
      <div class="popup-nav">
        <div class="popup-nav-bg"></div>
        <div class="popup-nav-box">
          <ul class="popup-nav-ul flex-col">
            <li @click="ScrollToID('topID')"
              :class="['popup-nav-li', languageTypeActive === '1' ? 'popup-mb30' : `popup-mb40 popup-nav-li-chinese`]">
              <span class="popup-nav-li-span">{{ languageTypeActive === '1' ? 'TOP' : '首页' }} </span>
            </li>
            <li @click="ScrollToID('textID')" :class="['popup-nav-li', languageTypeActive === '1' ? 'popup-mb30' : `popup-mb40 popup-nav-li-chinese`]">
              <span class="popup-nav-li-span">{{ languageTypeActive === '1' ? 'STORY' : '故事介绍' }} </span>
            </li>
            <li @click="ScrollToID('OVAsID')" :class="['popup-nav-li', languageTypeActive === '1' ? 'popup-mb30' : `popup-mb40 popup-nav-li-chinese`]">
              <span class="popup-nav-li-span">{{ languageTypeActive === '1' ? 'TEASER' : '样片' }} </span>
            </li>
            <li @click="ScrollToID('characterID')" :class="['popup-nav-li', languageTypeActive === '1' ? 'popup-mb30' : `popup-mb40 popup-nav-li-chinese`]">
              <span class="popup-nav-li-span">{{ languageTypeActive === '1' ? 'CHARACTERS' : '角色介绍' }} </span>
            </li>
            <li @click="ScrollToID('rollID')" :class="['popup-nav-li', languageTypeActive === '1' ? 'popup-mb30' :`popup-mb40 popup-nav-li-chinese`]">
              <span class="popup-nav-li-span">{{ languageTypeActive === '1' ? 'CONCEPT ART' : '概念设定' }} </span>
            </li>
            <li @click="contactShow"
              :class="['popup-nav-li', languageTypeActive === '1' ? 'popup-mb30' : `popup-mb40 popup-nav-li-chinese`]">
              <span class="popup-nav-li-span">{{ languageTypeActive === '1' ? 'CONTACT US' : '联系我们' }}<img
                  class="popup-nav-li-span-contactUS" src="@/assets/icon/contactUS.png" alt=""> </span>
            </li>
            <!-- <li @click="contactShow" :class="['popup-nav-li']">
              <img class="popup-youtube-icon" @mouseover="hoverYoutubeImage" @mouseleave="normalYoutubeImage"
                :src="youtubeImage" alt="">
              <img class="popup-facebook-icon" @mouseover="hoverFacebookImage" @mouseleave="normalFacebookImage"
                :src="facebookImage" alt="">
            </li> -->
            <!-- <li class="popup-nav-language-select-li">
              <span @click="selectLanguage('1')"
                :class="['popup-nav-language-select-li-span', languageTypeActive === '1' ? 'popup-nav-language-select-li-span-active' : '']">EN</span>
              /
              <span @click="selectLanguage('2')"
                :class="['popup-nav-language-select-li-span', languageTypeActive !== '1' ? 'popup-nav-language-select-li-span-active' : '']">中</span>
            </li> -->
          </ul>
        </div>
        <div class="popup-url-icon-wrap">
          <!-- <img class="popup-youtube-icon" @mouseover="hoverYoutubeImage" @mouseleave="normalYoutubeImage"
            :src="youtubeImage" alt="">
          <img class="popup-facebook-icon" @mouseover="hoverFacebookImage" @mouseleave="normalFacebookImage"
            :src="facebookImage" alt=""> -->
            <div class="popup-url-logo" @click="goUrl">
              <span>{{ languageTypeActive !== '1' ? '得不了动画工作室' : 'Produced by Debris Studio' }}</span>
              <div class="logoImg"></div>
              <img class="popup-url-logo-contactUS" src="@/assets/icon/contactUS.png" alt="">
            </div>
        </div>
      </div>
      <img @click="closePopup" class="el-icon-close-popup" @mouseover="hoverClosePopupImage"
        @mouseleave="normalClosePopupImage" :src="closePopupImage" alt="">
    </section>
    <section class="popup-contact-id popup">
      <div class="popup-contact">
        <div class="popup-contact-box">
          <h3 class="popup-contact-box-title mb20">{{ languageTypeActive === '1' ? 'CONTACT US' : '联系我们' }}</h3>
          <p class="popup-contact-box-text mb20">{{ languageTypeActive === '1' ? 'We value your feedback. If you have any suggestions or comments, please fill out the form below to share with us.':'我们重视您的反馈。如果您有任何建议或意见，请填写以下表格与我们分享：' }}
          </p>
          <div class="popup-contact-form">
            <div class="popup-contact-form-input-box mb46">
              <p class="popup-contact-form-input-box-p mb10">{{ languageTypeActive === '1' ? 'Name' : '名字' }}</p>
              <input v-model="name" maxlength="30"
                :class="['popup-contact-form-input-box-input', nameErrorMessage ? 'popup-contact-form-input-box-input-active' : '']"
                type="text" :placeholder="languageTypeActive === '1' ? 'Please enter your name' : '请输入您的名字'">
              <p v-show="nameErrorMessage" class="email-error-message">* {{ nameErrorMessage }}</p>
            </div>
            <div class="popup-contact-form-input-box mb46">
              <p class="popup-contact-form-input-box-p mb10">{{ languageTypeActive === '1' ? 'Email' : '邮箱' }}</p>
              <input v-model="email" maxlength="50"
                :class="['popup-contact-form-input-box-input', emailErrorMessage ? 'popup-contact-form-input-box-input-active' : '']"
                type="text" :placeholder="languageTypeActive === '1' ? 'Please enter your email address' : '请输入您的邮箱地址'">
              <p v-show="emailErrorMessage" class="email-error-message">* {{ emailErrorMessage }}</p>
            </div>
            <div class="popup-contact-form-input-box mb46">
              <p class="popup-contact-form-input-box-p mb10">{{ languageTypeActive === '1' ? 'Message' : '留言' }}</p>
              <textarea maxlength="500" v-model="describe" type="textarea" rows="4"
                :class="['popup-contact-form-input-box-textarea', descriptionErrorMessage ? 'popup-contact-form-input-box-input-active' : '']"
                :placeholder="languageTypeActive === '1' ? 'Please enter your message or inquiry here' : '请在此输入您的建议或反馈'"></textarea>
              <p v-show="descriptionErrorMessage" class="email-error-message">* {{ descriptionErrorMessage }}</p>
            </div>
            <div class="popup-contact-form-button-wrap">
              <button v-preventReClick v-if="!popupBtn" @click="submit"
                class="popup-contact-form-button popup-contact-form-button-click">{{ languageTypeActive === '1' ?
                'SUBMIT' : '提交' }}</button>
              <button v-preventReClick v-else class="popup-contact-form-button">{{ languageTypeActive === '1' ?
                'SUBMIT' : '提交' }}</button>
            </div>
          </div>
        </div>
      </div>
      <img @click="closePopupContact" class="el-icon-close-popup" @mouseover="hoverClosePopupImage"
        @mouseleave="normalClosePopupImage" :src="closePopupImage" alt="">
    </section>
    <transition name="fade">
      <section class="img-modal" v-show="isImageVisible" @click="hideImage">
        <img class="" :src="visibleImage" alt="Visible Image" @click.stop />
      </section>
    </transition>
    <Message v-if="MessageShow" :text="MessageText" :type="MessageType" />
  </div>
</template>

<script>
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
// import ScrollToPlugin from 'gsap/ScrollToPlugin';
import { mapState, mapMutations } from 'vuex'
import { localCanhaiLanguageSet } from '../utils'
import Message from '@/components/Message'
export default {
  name: 'Home',
  data() {
    return {
      // **********popup
      popupUrl: require('@/assets/icon/list_icon.png'),
      popupActiveUrl: require('@/assets/icon/list_icon_active.png'),
      popupImage: require('@/assets/icon/list_icon.png'),
      // **********close popup
      closePopupUrl: require('@/assets/icon/popup_close.png'),
      closePopupActiveUrl: require('@/assets/icon/popup_close_active.png'),
      closePopupImage: require('@/assets/icon/popup_close.png'),
      // **********
      youtubeUrl: require('@/assets/icon/youtube_icon.png'),
      youtubeActiveUrl: require('@/assets/icon/youtube_icon_active.png'),
      youtubeImage: require('@/assets/icon/youtube_icon.png'),
      // **********
      facebookUrl: require('@/assets/icon/facebook_icon.png'),
      facebookActiveUrl: require('@/assets/icon/facebook_icon_active.png'),
      facebookImage: require('@/assets/icon/facebook_icon.png'),
      // **********
      rollImage1: require('@/assets/img/box_li_img1.png'),
      rollImage2: require('@/assets/img/box_li_img2.png'),
      rollImage3: require('@/assets/img/box_li_img3.png'),
      rollImage4: require('@/assets/img/box_li_img4.png'),
      rollImage5: require('@/assets/img/box_li_img5.png'),
      rollImage6: require('@/assets/img/box_li_img6.png'),
      // **********
      // gif:require('@/assets/gkb/image1.png'),
      // imgs:[
			// 	require('@/assets/gkb/image1.png'),
      //   require('@/assets/gkb/image2.png'),
      //   require('@/assets/gkb/image3.png'),
      //   require('@/assets/gkb/image4.png'),
      //   require('@/assets/gkb/image5.png'),
      //   require('@/assets/gkb/image6.png'),
      //   require('@/assets/gkb/image7.png')
			// ],
      // currentIndex:0,
      // timerId:null,
      // animationFrameId:null,
      // ***************
      loadingPCShow:true,//loading加载懂消失
      visibleImage:'',//点击框内图片展示的它自己图片
      isImageVisible: false,
      comeInLoadingShow:false,//判断comeInLoading是否已经执行了
      // ***********
      // ***************
      timer: null,
      name: '',
      email: '',
      describe: '',
      emailError: false,
      emailErrorMessage: '',
      nameErrorMessage: '',
      descriptionErrorMessage: '',
      isPlaying: false,
      isMuted: true,
      navAndContact: true,
      footeryoutubeType: true,
      footerFacebookType: true,
      roleNum: 0,//角色介绍
      videoError: false,//视频加载失败
      ge_cn: "只身流落到华丽岛的人类，与本地女子结合生下了女儿唐。为追逐名利，醉心于发明“魔幻小玩意儿” ，抛弃唐离家出走。",
      ge_en: 'A human, stranded on “Island Splendor”, had a daughter named Tang with a local woman. Obsessed with fame and fortune, he abandoned Tang and left home to pursue his passion for inventing "magical gadgets."',//角色介绍
      tang_cn: '葛的女儿，母亲是当地人，因难产去世。自己又被父亲抛弃，独自在残骸的世界中长大。苦难的经历锻造了她的坚毅、狂野、执着和善良。在偶然得知了父亲的下落后，踏上了寻找父亲的旅程。',
      tang_en: "Ge's daughter, with a local woman as her mother, lost her mother to childbirth complications. She was then abandoned by her father and grew up alone in a world of wreckage. The hardships she endured forged her resilience, wildness, determination, and kindness. Upon accidentally learning her father's whereabouts, she embarked on a journey to find him.",
      niao_cn: '为了躲避肉食动物的追杀躲进唐的花园，与唐相识，伴随唐长大，是唐的伙伴和坐骑。通人性，比通常的鸵鸟高大。',
      niao_en: "To escape from predators, it hid in Tang's garden, where it met Tang and grew up alongside her. It became Tang's companion and mount. This creature, taller and more intelligent than typical ostriches, shared a deep bond with Tang.",
      popupBtn: false,
      MessageText: 'Submission failed. Please check your network configuration and try again later.',
      MessageShow: false,
      MessageType: '',
      textboxpEn: [
        { text: 'This short film tells the story of a young girl who, after her father leaves home, grows up alone in a world filled with wreckage. Years later, when she reunites with her father...' },
        { text: '***' },
        { text: `"This film is created by the director out of an obsession with the beauty of wreckage. It also serves as an exploration of the style and production methods for a future feature-length film.` },
        { text: ` Wreckage symbolizes the fragmentation of the broader real world, as well as the imperfections and mutations of personal spiritual homes. Within each person, there might be a wreckage that can be rebuilt, dismantled, or left to continue decaying. The pursuit of perfection in life often becomes a driving force for individuals.` },
        { text: `In the debris of love, the search for its rebuilding begins."` },
        { text: '— Xi Feng' },
      ],
      textboxpCh: [
        { text: '本片讲述一个幼小的女孩，在父亲离家出走后，独自在遍布残骸的环境中艰难成长。多年后，当她再次与父亲相遇…' },
        { text: '***' },
        { text: '“本片是导演基于对残骸之美的痴迷而创作，同时这部短片是对未来长片电影风格及制作模式的探索。残骸的世界，破碎的情感，但人类从不放弃对爱的追寻。' },
        { text: '残骸，象征着广义的现实世界的支离破碎，也印证着个人精神家园的残缺和变异。每个人心里都可能有一座或可重建、或可拆除、或可继续荒芜的残骸。但让人生完美却总是能够成为人生的一种动力。' },
        { text: '残骸的世界，破碎的情感，但人类从不放弃对爱的追寻。”' },
        { text: '——西风' },
      ]
    }
  },
  mounted() {
    this.initGsap()
    emailjs.init("lCvE3WOqbqiit3J3q");
    this.isPlaying = this.$refs.videoRef.paused;
  },
  components: {
    Message
  },
  computed: {
    ...mapState(['languageTypeActive']),
  },
  methods: {
    ...mapMutations({
      setLang: 'setLang'
    }),
    // 初始化动画库，然后触发每个动画的设置
    initGsap() {
      // this.startRotation()
      gsap.registerPlugin(ScrollTrigger);
      // this.timer = setInterval(() => {
      //   if (document.readyState === 'complete') {
      //     this.comeInLoading()
      //     window.clearInterval(this.timer)
      //   }
      // }, 0)
      this.timer = setTimeout(() => {
        if(!this.comeInLoadingShow){
          this.comeInLoading(); // 超时后也结束loading
        }
        if (this.timer) {
          window.clearInterval(this.timer);
          this.timer = null;
        }
      }, 10000);
      const videoElement = document.getElementById('topVideoID');
      videoElement.addEventListener('loadedmetadata', () => {
        if(!this.comeInLoadingShow){
          this.comeInLoading(); // 超时后也结束loading
        }
      });
      // document.addEventListener('DOMContentLoaded', () => {
      //   if(!this.comeInLoadingShow){
      //     this.comeInLoading(); // 超时后也结束loading
      //   }
      // });
    },
    //滚动导航栏图片跟背景逐渐平移
    createPanUpAnimation() {
      // const gif = document.querySelector('.parallax-gif');
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: ".hero",
          start: "top top",
          end: "bottom top",
          // onLeave: () => gsap.set(gif, { display: 'none' }),  // 当.hero完全离开视窗时立即隐藏.gif
          // onEnterBack: () => gsap.set(gif, { display : 'inline' }),  // 当.hero从上方进入视窗时立即显示.gif
          scrub: true
        }
      });
      gsap.utils.toArray(".parallax").forEach(layer => {
        const depth = layer.dataset.depth;
        const movement = -(layer.offsetHeight * depth)
        tl.to(layer, { y: movement, ease: "none" }, 0)
      });

      // const depth = gif.dataset.depth;
      // const movement = -(gif.offsetHeight * depth)
      // tl.to(gif, { y: movement, ease: "none" }, 0);
    },
    //多个框按导航栏顺序滚动文字逐渐高亮
    createMoreFontHighlightAnimation() {
  document.querySelectorAll('.text-box-p').forEach((text, index, array) => {
    gsap.killTweensOf(text.querySelectorAll('span'));
    let words = '';
    if (this.languageTypeActive == '1') {
      words = this.textboxpEn[index].text.split(" "); // 将文本拆分成单词
    } else {
      words = this.textboxpCh[index].text.split("");
    }
    text.innerHTML = ""; // 清空原始文本

    // 根据语言类型调整高亮位置
    let top, bottom;
    if (this.languageTypeActive == '1') { // 英文
      let step = 60 / array.length;
      top = 80 - index * step;
      bottom = top - step;
    } else { // 中文
      let lineHeight = 2; // 每行高度
      let lines = Math.ceil(text.clientHeight / lineHeight); // 计算文本行数
      top = 50 - index * lines; // 调整高亮位置
      bottom = top - lines;
    }

    // 为每个单词创建一个span并赋予初试颜色
    words.forEach((word, wordIndex) => {
      let span = document.createElement('span');
      span.textContent = word;
      text.appendChild(span);
        // 如果不是最后一个单词，添加一个空格
      if (wordIndex < words.length - 1) {
        text.appendChild(document.createTextNode(' '));
      }
    });

    // 创建每个span的动画
    gsap.fromTo(text.querySelectorAll('span'), {
      color: '#634F44',
      display: 'inline-block',
    }, {
      opacity: '1', // 高亮颜色 - 如Tomato色
      color: '#EFE1C6',
      stagger: 0.05, // 每个单词按顺序依次高亮
      ease: "none",
      scrollTrigger: {
        trigger: text, // 每个文本块都是其自身触发器
        start: `top ${top}%`, // 计算每个框在视口顶部的位置开始动画
        end: `bottom ${bottom}%`, // 计算每个框在视口底部的位置结束动画
        toggleActions: "restart none none reset",
        scrub: true // 平滑过渡动画效果
      }
    });
  });
},
    // 框内图片左右滚动
    scrollingLeftRight() {
      gsap.utils.toArray('.roll-box').forEach((section, index) => {
        const w = section.querySelector('.wrapper');
        const [x, xEnd] = (index % 2) ? [(w.scrollWidth) * -1, 0] : ['100%', (w.scrollWidth - section.offsetWidth) * -1];
        gsap.fromTo(w, { x }, {
          x: xEnd,
          scrollTrigger: {
            trigger: '.roll',
            scrub: 0.5
          }
        });
      })
    },
    showImage(image){
      this.visibleImage = image;
      this.isImageVisible = true;
    },
    hideImage() {
      this.isImageVisible = false;
    },
    // 图片左右平移
    translationAnimation() {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            entry.target.style.transform = 'none';
          } else {
            entry.target.style.transform = 'translate(-10%, 0)';
          }
        },
        {
          threshold: 0.1
        }
      );

      const elements = this.$el.querySelectorAll('.move-right');
      elements.forEach(element => observer.observe(element));

      const observer1 = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            entry.target.style.transform = 'none';
          } else {
            entry.target.style.transform = 'translate(10%, 0)';
          }
        },
        {
          threshold: 0.1
        }
      );

      const elements1 = this.$el.querySelectorAll('.move-left');
      elements1.forEach(element => observer1.observe(element));
    },
    // 播放暂停按钮
    togglePlay() {
      let video = this.$refs.videoRef;
      if (video.paused) {
        video.play();

      } else {
        video.pause();

      }
    },
    // 静音按钮
    toggleMute() {
      this.isMuted = !this.isMuted;
    },
    // 播放
    handlePlay() {
      this.isPlaying = false;
    },
    // 暂停
    handlePause() {
      this.isPlaying = true;
    },
    // 错误
    handleError() {
      this.videoError = true;
    },
    // 重新加载视频
    reloadVideo() {
      this.videoError = false;
      this.$refs.videoRef.load();

    },
    // 角色介绍
    roleAnimation() {
      gsap.utils.toArray(".role-box-li").forEach((container, index) => {

        // 从当前 container 中选择 ".information" 元素
        let info = this.$refs.boxDescribe;
        gsap.set(info, { opacity: 0, display: 'none' });
        // 从当前 container 中选择 ".silhouette .cover" 元素
        // 创建一个新的 GSAP 时间线，初始状态为暂停
        let tl = gsap.timeline({ paused: true });
        // 然后立即将 silhouette 元素的透明度设置为 0
        tl.to('.role-box-li', { opacity: 0, ease: "power2.out", duration: 1 }, 0).to(info, { opacity: 1, display: 'block', ease: "power2.out", duration: 1, transformOrigin: "center" }, 0).to('.shade2', { opacity: 0, ease: "power2.out", duration: 1, transformOrigin: "center" }, 0);
        // 当鼠标进入 container 时，将时间线的速度设置为 1 并播放动画
        container.addEventListener("click", () => {
          this.roleNum = index
          tl.timeScale(1).play()
        });
        // 当鼠标离开 container 时，将时间线的速度设置为 3 并反向播放动画（也就是回到初始状态）
        info.addEventListener("click", () => tl.timeScale(1).reverse());
      });
    },
    topLeftIcon() {
      let Iconbox = document.querySelector('.footer-box-topLeft');
      let tl = gsap.timeline({ paused: true });
      tl.to('.topLeft-icon', { yPercent: -100, repeat: -1, duration: 2, }, 0);
      Iconbox.addEventListener("mouseenter", () => {
        gsap.set('.topLeft-icon', { yPercent: 100 });
        tl.timeScale(1).restart()
      });
      Iconbox.addEventListener("mouseleave", () => {
        gsap.set('.topLeft-icon', { yPercent: 0 });
        tl.kill()
      });
    },
    topLeftIcon2() {
      let Iconbox = document.querySelector('.footer-logo');
      let tl = gsap.timeline({ paused: true });
      tl.to('.topLeft-icon2', { yPercent: -100, repeat: -1, duration: 2, }, 0);
      Iconbox.addEventListener("mouseenter", () => {
        gsap.set('.topLeft-icon2', { yPercent: 100 });
        tl.timeScale(1).restart()
      });
      Iconbox.addEventListener("mouseleave", () => {
        gsap.set('.topLeft-icon2', { yPercent: 0 });
        tl.kill()
      });
    },
    // 弹窗popup，从上到下
    popup() {
      const modal = document.querySelector('.popup-id');
      gsap.to(modal, { top: 0, duration: 1, ease: "power4.inOut" })
    },
    // 关闭弹窗
    closePopup() {
      const modal = document.querySelector('.popup-id');
      gsap.to(modal, { top: "-100vh", duration: 1, ease: "power4.inOut" })
    },
    // 联系弹窗
    contactShow() {
      this.closePopup()
      setTimeout(() => {
        this.popupContact()
      }, 1000)
    },
    // 弹窗popup，从上到下
    popupContact() {
      const modal = document.querySelector('.popup-contact-id');
      gsap.to(modal, { top: 0, duration: 1, ease: "power4.inOut" })

    },
    startRotation(){
      // this.timerId = setInterval(() => {
      //   this.currentIndex = (this.currentIndex + 1) % this.imgs.length;
      //   this.gif = this.imgs[this.currentIndex];
      // }, 50); // 每100毫秒更换一次图片
      this.gif = this.imgs[this.currentImageIndex];
      this.animateImages();
    },
    animateImages(){
      const animate = () => {
        this.currentIndex = (this.currentIndex + 1) % this.imgs.length;
        this.gif = this.imgs[this.currentIndex];
        this.animationFrameId = requestAnimationFrame(animate);
      };

      this.animationFrameId = requestAnimationFrame(animate);
    },
    stopRotation() {
      // clearInterval(this.timerId);
      cancelAnimationFrame(this.animationFrameId);
      this.animationFrameId = null;
    },
    // 数据加载完成动画
    comeInLoading() {
      this.comeInLoadingShow = true
      const modal = document.querySelector('.come-in-loading');
      // const modalcenter = document.querySelector('.loading-img');
      // const modalcenter = document.querySelector('.loading-img-gif');

      // const modalcenter = document.querySelector('.loading-video');
      // const layertop01gif = document.querySelector('.layer-top01-img');
      const Loadingshow = gsap.timeline();
      // Loadingshow.to(modalcenter, { duration:1})
      // Loadingshow.to(modalcenter, { x: window.innerWidth,duration: 2, ease: "none",onComplete: () => {
      //   modalcenter.style.display = 'none';
      //   this.stopRotation()
      // }})
      // alert(1)
      Loadingshow.to(modal, { duration:3,onComplete:()=>{this.createMoreFontHighlightAnimation()}})
      // alert(2)
      Loadingshow.to(modal, { x: '100vw', duration:1,  ease: "power4.in" ,onComplete: () => {
        this.loadingPCShow = false
        // alert(3)
        this.createPanUpAnimation()
        // alert(3)
        this.scrollingLeftRight()
        // alert(3)
        this.translationAnimation()
        // alert(3)
        this.roleAnimation()
        this.topLeftIcon()
        this.topLeftIcon2()
        // alert(3)
        this.$refs.topVideo.play()
        // alert(3)
      }})
      // Loadingshow.to(layertop01gif, {
      //   duration: 0.1, // 短暂持续时间，以确保它位于时间线上
      //   onComplete: () => {
      //     layertop01gif.style.display = 'inline';
      //   }
      // })
    },
    // 关闭弹窗
    closePopupContact() {
      const modal = document.querySelector('.popup-contact-id');
      this.name = ''
      this.email = ''
      this.describe = ''
      this.emailErrorMessage = ''
      gsap.to(modal, { top: "-100vh", duration: 1, ease: "power4.inOut" })
    },
    // 滚动到指定ID
    ScrollToID(targetId) {
      this.closePopup()
      const targetElement = document.getElementById(targetId);
      setTimeout(() => {
        targetElement.scrollIntoView({
          behavior: 'smooth'
        });
      }, 500)
    },
    selectLanguage(val) {
      this.setLang(val)
      localCanhaiLanguageSet(val)
      this.createMoreFontHighlightAnimation()
    },
    // **********************Youtube
    hoverYoutubeImage() {
      this.youtubeImage = this.youtubeActiveUrl;
    },
    normalYoutubeImage() {
      this.youtubeImage = this.youtubeUrl;
    },
    // **********************Facebook
    hoverFacebookImage() {
      this.facebookImage = this.facebookActiveUrl;
    },
    normalFacebookImage() {
      this.facebookImage = this.facebookUrl;
    },
    // **********************popup
    hoverPopupImage() {
      this.popupImage = this.popupActiveUrl;
    },
    normalPopupImage() {
      this.popupImage = this.popupUrl;
    },
    // **********************closepopup
    hoverClosePopupImage() {
      this.closePopupImage = this.closePopupActiveUrl;
    },
    normalClosePopupImage() {
      this.closePopupImage = this.closePopupUrl;
    },
    // **********************
    submit() {
      if (!this.name) {
        this.nameErrorMessage = this.languageTypeActive === '1' ? 'Name cannot be empty' : '姓名不能为空'
        return
      } else {
        this.nameErrorMessage = ''
      }
      if (!this.email) {
        this.emailErrorMessage = this.languageTypeActive === '1' ? 'Email cannot be empty' : '邮箱不能为空'
        return
      } else {
        if (!(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email))) {
          this.emailErrorMessage = this.languageTypeActive === '1' ? 'Invalid email format' : '邮箱格式不正确'
          return
        } else {
          this.emailErrorMessage = ''
        }
      }
      if (!this.describe) {
        this.descriptionErrorMessage = this.languageTypeActive === '1' ? 'Message cannot be empty' : '留言不能为空'
        return
      } else {
        this.descriptionErrorMessage = ''
      }
      this.popupBtn = true
      emailjs.send("service_2pg3jeh", "template_do6k3io", {
        name: this.name,
        email: this.email,
        message: this.describe
      }).then(res => {
        this.name = ''
        this.email = ''
        this.describe = ''
        this.emailErrorMessage = ''
        this.MessageText = this.languageTypeActive === '1' ? 'Submission successful.Thank you for your feedback. We will get back to you as soon as possible.' : '提交成功，感谢您的反馈，我们会尽快与您联系。'
        this.MessageType = 'success'
        this.MessageShow = true
        this.popupBtn = false
        setTimeout(() => {
          this.MessageShow = false
        }, 3000)
      }, err => {
        this.MessageType = 'warning'
        this.MessageText = this.languageTypeActive === '1' ? 'Submission failed. Sorry, there was an error during submission. Please try again later.' : '提交失败，抱歉，提交过程中出现错误。请稍后重试。'
        this.MessageShow = true
        this.popupBtn = false
        setTimeout(() => {
          this.MessageShow = false
        }, 3000)
        console.log('err', err)
      })
    },
    // 跳转官网
    goUrl() {
      window.open('https://debrisanimation.com')
    }

  },

}
</script>

<style lang="scss" scoped>
.home {
  position: relative;
  overflow: hidden;

  .list-icon-click {
    position: fixed;
    top: 40rem;
    right: 40rem;
    font-size: 20rem;
    color: #fff;
    z-index: 9;
    width: 80rem;
    height: 80rem;
    min-width: 40rem;
    min-height: 40rem;
    cursor: pointer;
  }
  .top-text-language-wrap{
    position: absolute;
    top: 69rem;
    right: 200rem;
    font-size: 36rem;
    color: #EFE1C6;
    font-family: "ZumboCity", "WangQiangShuFaTi";
    z-index: 6;
    .top-text-language-select-li-span-en,.top-text-language-select-li-span-chinese {
      opacity: 0.5;
      cursor: pointer;
    }
    .top-text-language-select-li-span-chinese{
      font-size: 30rem;
      line-height: 37rem;
      display: inline-block;
    }
    .top-text-language-select-li-span-active {
      opacity: 1;
    }
  }
  .top {
    position: relative;
    // background: #D5C6B2;
    background-color: #C9B69F;
    z-index: -1;

    .hero {
      height: 100vh;
      overflow: hidden;
      position: relative;
      min-height: 100vh;
    }

    .layer-top-bg-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: auto;
    }

    .layer-top-left-renson-img {
      position: absolute;
      bottom: 0;
      left: 10rem;
      width: 443rem;
      min-width: 250rem;
      height: auto;
    }

    .layer-top-center-img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 420rem;
      width: 300rem;
      min-width: 200rem;
      height: auto;
      display: none;
    }

    .layer-top01-img {
      position: absolute;
      left: 0;
      bottom: 0;
      // transform: translateX(-50%) translateY(-50%);
      width: 1920rem;
      height: auto;
      display: none;
    }
    
    .top-text-img-wrap {
      position: absolute;
      top: 50%;
      transform: translateY(-52%);
      right: 240rem;
      display: flex;
      flex-direction: column;
    }
    .top-text-img-wrap-en{
      position: absolute;
      top: 50%;
      transform: translateY(-40%);
      right: 240rem;
      display: flex;
      flex-direction: column;
    }
    .layer-logo-img {
      width: 232rem;
      min-width: 120rem;
      height: auto;
      margin-right: 30rem;
    }
    .layer-logo-img-1{
      width: 113rem;
      min-width: 60rem;
      height: auto;
      margin-left: 40rem;
    }
    .layer-text-img {
      width: 690rem;
      min-width: 120rem;
      height: auto;

    }
    .layer-text-img-1 {
      width: 686rem;
      min-width: 300rem;
      height: auto;

    }
    .top-text-en-wrap {
      text-align: center;
      margin-bottom: 140rem;
    }
    .top-text-en-wrap-1 {
      text-align: center;
      margin-bottom: 110rem;
    }
    .layer-text-en {
      // position: absolute;
      // bottom: 56rem;
      // right: 60rem;
      margin-left: 30rem;
      width: 254rem;
      min-width: 120rem;
      height: auto;
    }
    .layer-text-en-1{
      width: 686rem;
      min-width: 200rem;
      height: auto;
    }
    .layer-text-chinese-title1{
      font-size: 37rem;
      color: #EFE1C6;
      line-height: 47rem;
      text-shadow: 2rem 2rem 0 #28211C;
      text-align: right;
      font-style: normal;
      font-family: "ZumboCity", "WangQiangShuFaTi";
    }
    .layer-text-chinese-title1-1{
      font-size: 41rem;
      color: #EFE1C6;
      line-height: 47rem;
      text-shadow: 2rem 2rem 0 #28211C;
      text-align: right;
      font-family: "ZumboCity", "WangQiangShuFaTi";
    }
    .layer-text-chinese-title2{
      font-size: 26rem;
      color: #EFE1C6;
      line-height: 33rem;
      text-shadow: 2rem 2rem 0 #28211C;
      text-align: center;
      display: inline-block;
      font-family: "ZumboCity", "WangQiangShuFaTi";
      padding-right: 18rem;
    }
    .layer-text-chinese-title2-1{
      font-size: 28rem;
      color: #EFE1C6;
      line-height: 35rem;
      text-shadow: 2rem 2rem 0 #28211C;
      text-align: left;
      display: inline-block;
      font-family: "ZumboCity", "WangQiangShuFaTi";
    }
    .layer-top-bottom-img {
      position: absolute;
      bottom: -20rem;
      left: 0;
      width: 100%;
      height: auto;
    }

    .layer {
      background-position: bottom center;
      background-size: auto;
      background-repeat: no-repeat;
      width: 100%;
      height: 100vh;
      position: fixed;
      min-height: 100vh;
      z-index: -1;
    }

    .layer-bg {
      background-image: url('../assets/img/top_bg.png');
      background-size: cover;
      background-position: center center;
    }

    .layer-bg-video {
      width: 100%;
      height: 100%;
      /* object-fit确保视频内容填充整个元素，同时保持其宽高比 */
      object-fit: cover;
      /* 居中显示视频内容 */
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

    }
  }

  .text {
    position: relative;
    background: url('../assets/img/text_bg_img.png') no-repeat;
    background-size: 100% 100%;
    padding: 126rem 0 136rem 0;

    .textID-top-bottom-img {
      position: absolute;
      top: -236rem;
      left: 0;
      width: 100vw;
      height: auto;
    }

    .text-box {
      width: 1200rem;
      margin: 0 auto;
      text-align: center;
      position: relative;
    }

    .text-box2 {
      width: 1000rem;
      margin: 0 auto;
      text-align: center;
    }
    .text-boxIcon{
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .text-box-icon {
      width: 97rem;
      height: 114rem;
      margin-left:25rem;
      background: url('../assets/icon/textboxIcon.png') no-repeat;
      background-size: 100% 100%;
    }

    .text-box-p {
      font-family: "ZumboCity", "WangQiangShuFaTi";
      font-weight: normal;
      font-size: 64rem;
      color: #634F44;
      line-height: 74rem;
      text-align: center;
      font-style: normal;
      transition: color 0.3s ease-out;
      text-align: center;
    }

    .text-box-p2 {
      font-size: 44rem;
      color: #634F44;
      line-height: 48rem;
      text-align: center;
      font-style: normal;
    }

    .text-box-p-chinese {
      font-size: 54rem;
      line-height: 78rem;
    }
    .text-box-p-chinese2 {
      font-size: 42rem;
      line-height: 60rem;
    }
  }

  .OVAs {
    background: url("../assets/img/OVAs_bg_img.png") no-repeat;
    background-size: 100% 100%;
    position: relative;
    padding: 278rem 0 327rem 0;

    .OVAs-top {
      position: absolute;
      width: 100%;
      height: 461rem;
      top: -315rem;

      .OVAs-top-img {
        position: absolute;
        left: 0;
        height: 461rem;
        object-fit: cover;
        transform: translate(-10%, 0);
        transition: transform 2500ms;
      }

      .OVAs-top-img1 {
        position: absolute;
        right: 0;
        height: 463rem;
        object-fit: cover;
        transform: translate(10%, 0);
        transition: transform 2500ms;
      }
    }


    .grass-img {
      position: absolute;
      top: 823rem;
      left: 0;
      height: 290rem;
      object-fit: cover;
      transform: translate(-10%, 0);
      transition: transform 2500ms;
    }

    .ship-img {
      position: absolute;
      top: 1115rem;
      right: 0;
      height: 208rem;
      object-fit: cover;
      transform: translate(10%, 0);
      transition: transform 2500ms;
    }

    .OVAs-center {
      position: relative;
      width: 1500rem;
      margin: 0 auto;

      .video-box {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .video-border {
          width: calc(100% + 2rem);
          background: url('../assets/img/video_border.png') no-repeat;
          background-size: cover;
          height: calc(100% + 4rem);
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        .video-err {
          position: absolute;
          z-index: 2;

          p {
            font-family: "ZumboCity", "WangQiangShuFaTi";
            font-weight: normal;
            font-size: 40rem;
            color: #EFE1C6;
            line-height: 30rem;
            text-align: center;
            font-style: normal;
          }

          .videoerr-ImgBtn {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 61rem;

          }

          img {
            width: 40rem;
            object-fit: contain;
            margin-right: 16rem;
          }

          span {
            font-family: "ZumboCity", "WangQiangShuFaTi";
            font-weight: normal;
            font-size: 40rem;
            color: #EFE1C6;
            line-height: 30rem;
            text-align: right;
            font-style: normal;
          }
        }

        .cover_bg {
          position: absolute;
          z-index: 2;
          width: 100%;
          height: 100%;
          background: url('../assets/img/video_cover.png') no-repeat;
          background-size: 100% 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 82rem;
            height: 82rem;
          }
        }

        .video {
          width: 100%;
          height: 100%;
        }

        .play-btn {
          position: absolute;
          width: 100rem;
          height: 100rem;
          cursor: pointer;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        .muted-icon {
          position: absolute;
          color: #000;
          bottom: 80rem;
          right: 72rem;
          z-index: 2;

          img {
            width: 80rem;
            height: 80rem;
            cursor: pointer;
          }
        }
      }
    }


  }

  .character {
    background: url('../assets/img/character_bg_img.png') no-repeat;
    background-size: cover;
    padding: 96rem 0 155rem 0;
    position: relative;
    top:-1rem;

    .shade {
      position: absolute;
      top: -279rem;
      width: 100%;
      height: 976rem;
      background: url("../assets/img/shade_img.png") no-repeat;
      background-size: 100% 100%;
    }

    .shade2 {
      position: absolute;
      top: 352rem;
      width: 100%;
      height: 580rem;
      background: url("../assets/img/shade_img2.png") no-repeat;
      background-size: 100% 100%;
    }

    .character-title {
      position: absolute;
      top: -45rem;
      left: 50%;
      transform: translate(-50%, 0);

      h2 {
        font-family: "ZumboCity", "WangQiangShuFaTi";
        font-weight: normal;
        font-size: 90rem;
        color: #57453B;
        line-height: 68rem;
        letter-spacing: 2rem;
        text-align: center;
        font-style: normal;
        margin-bottom: 17rem;
      }
      .character-title-chinese{
        font-size: 80rem;
        line-height: 87rem;
        letter-spacing: 1rem;
      }

      img {
        display: block;
        height: 17rem;
        width: 188rem;
        margin: 0 auto;
      }
    }

    .character-box {
      width: 1500rem;
      margin: 0 auto;
      text-align: center;
      position: relative;




      .role-box {
        width: 100%;
        position: relative;
        height: 100%;

        .role-box-describe {
          width: 100%;
          height: 980rem;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        .describe-centre {
          width: 100%;
          height: 100%;
          position: relative;
        }

        .role-box-describe-bg {
          width: 100%;
          height: 100%;
          position: absolute;
          background: url("../assets/img/floating_bg_icon.png") repeat;
          background-size: 100% 100%;
        }

        .describe-centre-btn {
          width: 42rem;
          height: 42rem;
          position: absolute;
          top: 254rem;
          right: 134rem;
          background: url("../assets/mobileImg/close_icon.png") repeat;
          background-size: 100% 100%;
        }

        .describe-bg2 {
          background: url("../assets/img/floating_bg2_icon.png") repeat;
          background-size: 100% 100%;
        }

        .describe-bg3 {
          background: url("../assets/img/floating_bg3_icon.png") repeat;
          background-size: 100% 100%;
        }

        .skin_figure {
          position: absolute;
          left: 215rem;
          top: 89rem;
          width: 523rem;
          object-fit: contain;
        }



        .skin2 {
          left: 414rem;
          top: 309rem;
          width: 174rem;
          object-fit: contain;
        }

        .skin3 {
          left: 190rem;
          top: 114rem;
          width: 566rem;
          object-fit: contain;
        }

        .introduce {
          position: absolute;
          bottom: 237rem;
          right: 171rem;
          width: 642rem;
          font-family: "ZumboCity", "WangQiangShuFaTi";
          font-weight: normal;
          text-shadow: 2rem 2rem 0rem #57453B;

          h2 {
            font-weight: normal;
            font-size: 60rem;
            color: #FFF4E0;
            line-height: 45rem;
            letter-spacing: 6rem;
            text-align: center;
            font-style: normal;
            margin-bottom: 20rem;
            text-shadow: 2rem 2rem 0rem #57453B;
          }

          h4 {
            font-weight: normal;
            font-size: 40rem;
            color: #FFF4E0;
            line-height: 40rem;
            text-shadow: 2rem 2rem 0rem #57453B;
            text-align: center;
            font-style: normal;
          }
        }
        .introduce-chinese{
          h2 {
            font-size: 50px;
            line-height: 54rem;
          }

          h4 {
            font-size: 32rem;
            line-height: 46rem;
          }
        }

        .introduce2 {
          width: 735rem;
          bottom: 121rem;
          right: 147rem;
        }

        .introduce3 {
          bottom: 158rem;
          right: 277rem;
        }
      }

      .role-box-ul {
        width: 100%;
        padding: 14rem 142rem 49rem 211rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .role-box-li {
          margin-right: 20rem;
          cursor: pointer;
          // display: flex;
          // position: relative;

          .silhouette {
            width: 234rem;
            margin-bottom: 46rem;
            position: relative;

            // z-index: 9;
            img {
              position: relative;
              width: 100%;
              object-fit: contain;
              z-index: 2;
            }
            
          }
          .silhouette_boot{
            width:300rem;
            background: url("../assets/img/silhouette_boot.png") no-repeat;
            background-size: 100% 100%;
            height: 105rem;
            position:absolute;
            bottom: -24rem;
            left: -32rem;
            display: none;
          }

          .silhouette2 {
            width: 341rem;
            .silhouette_boot{
              left:40rem;
            }
          }

          .silhouette3 {
            width: 306rem;
            .silhouette_boot{
              left:-9rem;
              bottom: -15rem;
            }
          }

          span {
            font-family: "ZumboCity", "WangQiangShuFaTi";
            font-weight: normal;
            font-size: 60rem;
            color: #57453B;
            line-height: 45rem;
            letter-spacing: 2rem;
            text-align: right;
            font-style: normal;
          }
        }

        .role-box-li:hover {
          span {
            color: #94674D;
          }
          .silhouette_boot{
            display: block;
          }
        }
      }


    }

    .character-box-bottom {
      position: absolute;
      bottom: -2rem;
      width: 100%;
      height: 229rem;
      background: url("../assets/img/character_bottom_img.png") no-repeat;
      background-size: cover;
    }
  }

  .roll {
    background: url('../assets/img/atmosphere_img.png') no-repeat;
    background-size: 100% 100%;
    height: 1000rem;
    margin: -1rem 0;
    .rollID-title{
      margin-bottom: 86rem;
      .title-h2{
        font-family: "ZumboCity", "WangQiangShuFaTi";
        font-weight: normal;
        font-size: 90rem;
        color: #D4C3AF;
        line-height: 68rem;
        letter-spacing: 2rem;
        text-align: center;
        font-style: normal;
        text-transform: uppercase;
        margin-bottom: 16rem;
      }
      img{
        display: block;
        width: 247rem;
        height:19rem;
        margin: 0 auto;
      }
    }
    .rollID-height {
      height: 121rem;
    }

    .roll-box {
      padding-bottom: 60rem;
    }

    .roll-box-ul {
      display: flex;
    }

    .roll-box-li {
      margin-right: 20rem;
    }

    .roll-box-img {
      width: 786rem;
      height: 442rem;
    }
  }

  .bottom {
    height: 999rem;
    background: url('../assets/img/Bot_back.png') no-repeat;
    background-size: 100% 100%;
    padding-top: 778rem;

    .footer-box {
      width: 1500rem;
      margin: 0 auto;

      .footer-box-top {
        display: flex;
        justify-content: space-between;
        height: 119rem;

        .footer-box-topLeft {
          display: flex;
          align-items: center;
          // margin-top: 47rem;

          p {
            font-family: "ZumboCity", "WangQiangShuFaTi";
            font-weight: normal;
            font-size: 60rem;
            color: #57453B;
            letter-spacing: 2rem;
            text-align: left;
            font-style: normal;
            margin-right: 24rem;
            cursor: pointer;
          }
        }
        .footer-box-topLeft:hover {
          p {
            color: #3F3028;
          }
        }
        .topLeft-iconbox {
          width: 42rem;
          overflow: hidden;

          img {
            width: 100%;
            object-fit: contain;
          }

        }

        .footer-logo {
          height: 87rem;
          display: flex;
          cursor: pointer;
          align-items: flex-end;

          span {
            height: 21rem;
            font-family: "ZumboCity", "WangQiangShuFaTi";
            font-weight: normal;
            font-size: 28rem;
            color: #57453B;
            line-height: 21rem;
            letter-spacing: 1rem;
            text-align: center;
            font-style: normal;
          }

          .logoImg {
            width: 110rem;
            height: 87rem;
            margin-left: 26rem;
            margin-right: 20rem;
            background: url('../assets/img/logo_icon.png') no-repeat;
            background-size: 100% 100%;
          }

        }

        .footer-logo:hover {
          span {
            color: #3F3028;
          }

          .logoImg {
            background: url('../assets/img/logo_icon2.png') no-repeat;
            background-size: 100% 100%;
          }
        }
      }

      .partition-img {
        width: 100%;
        height: 3rem;
      }

      .footer-box-bottom {
        display: flex;
        justify-content:flex-end;
        align-items: center;
        height: 55rem;

        .footer-box-bottomIcon {
          display: flex;

          .icon {
            width: 46rem;
            object-fit: contain;
            margin-right: 20rem;
            cursor: pointer;
          }
        }

        p {
          font-family: "ZumboCity", "WangQiangShuFaTi";
          font-weight: normal;
          font-size: 28rem;
          color: #57453B;
          line-height: 21rem;
          letter-spacing: 1rem;
          text-align: center;
          font-style: normal;
        }
      }
    }
  }

  .popup {
    z-index: 10;
    position: fixed;
    top: -100vh;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    
    // display: none;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url('../assets/img/text_bg_img.png');
    display: flex;
    justify-content: center;
    align-items: center;

    .el-icon-close-popup {
      position: absolute;
      top: 20rem;
      right: 40rem;
      font-size: 30rem;
      font-weight: 600;
      width: 80rem;
      height: 80rem;
      min-width: 40rem;
      min-height: 40rem;
      color: #000;
      cursor: pointer;
    }

    .popup-nav {
      position: relative;
      width: 88%;
      height: 88%;
    }

    .popup-nav-box {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-image: url('../assets/img/popup_nav_bg.png');
      display: flex;
      align-items: center;

      .popup-nav-ul {
        margin-left: 12%;
      }

      .popup-nav-li {
        font-size: 86rem;
        // line-height: 86rem;
        color: #EFE1C6;
        letter-spacing: 10rem;
        vertical-align: middle;
        cursor: pointer;
        font-family: 'ZumboCity', 'WangQiangShuFaTi';
        display: inline-block;
      }
      .popup-mb30{
        margin-bottom: 32rem;
      }
      .popup-mb40{
        margin-bottom: 32rem;
      }
      .popup-nav-li-chinese {
        font-size: 72rem;
        // line-height: 72rem;
      }

      .popup-nav-li-span {
        position: relative;
        display: inline-block;
      }

      .popup-nav-li-span:hover::after {
        content: "";
        /* 伪元素必须有 content，即使为空 */
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -10rem;
        /* 将图片显示在文字下方 */
        width: 80%;
        /* 图片宽度与链接文字宽度一致 */
        height: 19rem;
        /* 图片高度，可以根据需要调整 */
        background-image: url('../assets/icon/navLine.png');
        background-repeat: no-repeat;
        background-size: 100% 20rem;
        transition: all 0.2s ease;
      }

      .popup-nav-li-span-contactUS {
        position: absolute;
        top: 50%;
        right: -66rem;
        transform: translateY(-50%);
        width: 56rem;
        height: 56rem;
      }

      .popup-nav-language-select-li {
        font-size: 50rem;
        line-height: 50rem;
        color: #EFE1C6;
        letter-spacing: 10rem;
        font-family: 'ZumboCity', 'WangQiangShuFaTi';
        vertical-align: middle;
      }

      .popup-nav-language-select-li-span {
        opacity: 0.5;
        cursor: pointer;
      }

      .popup-nav-language-select-li-span-active {
        opacity: 1;
      }
    }

    .popup-contact {
      display: flex;
      align-items: center;
      justify-content: center;

      .popup-contact-box {}

      .popup-contact-form {
        width: 500rem;
        margin: 0 auto;
      }

      .popup-contact-box-title {
        font-size: 60rem;
        color: #EFE1C6;
        line-height: 1;
        letter-spacing: 6rem;
        font-family: 'ZumboCity', 'WangQiangShuFaTi';
        text-align: center;
      }

      .popup-contact-box-text {
        max-width: 500rem;
        font-size: 30rem;
        color: #EFE1C6;
        // line-height: 22rem;
        letter-spacing: 3rem;
        font-family: 'ZumboCity', 'WangQiangShuFaTi';
        text-align: center;
        padding: 0 20rem;
      }

      .popup-contact-form-input-box {
        position: relative;
      }

      .popup-contact-form-input-box-p {
        font-weight: 400;
        font-size: 28rem;
        color: #F2E0C3;
        line-height: 1;
        font-family: 'ZumboCity', 'WangQiangShuFaTi';
      }

      .popup-contact-form-input-box-input {
        background: rgba(93, 93, 93, 0.4);
        border-radius: 3rem;
        width: 500rem;
        height: 44rem;
        font-size: 28rem;
        line-height: 1;
        font-family: 'ZumboCity', 'WangQiangShuFaTi';
        padding: 0 10rem;
        border: 1rem solid rgba(93, 93, 93, 0.4);
        color: #F2E0C3;
      }

      .popup-contact-form-input-box-input:focus {
        border: 1rem solid #F2E0C3;
      }

      .popup-contact-form-input-box-input-active {
        border: 1rem solid #FB4343;
      }
      .popup-contact-form-input-box-input::placeholder {
        line-height: 1;
        color: #7A7469;
      }

      .email-error-message {
        font-size: 28rem;
        color: #FB4343;
        position: absolute;
        top: 110%;
        left: 0;
        font-family: 'ZumboCity', 'WangQiangShuFaTi';
      }

      .popup-contact-form-input-box-textarea {
        background: rgba(93, 93, 93, 0.4);
        border-radius: 3rem;
        width: 500rem;
        font-size: 28rem;
        line-height: 1;
        color: #F2E0C3;
        font-family: 'ZumboCity', 'WangQiangShuFaTi';
        padding: 10rem 10rem;
        border: 1rem solid rgba(93, 93, 93, 0.4);
        resize: none;
      }

      .popup-contact-form-input-box-textarea:focus {
        border: 1rem solid #F2E0C3;
      }

      .popup-contact-form-button-wrap {
        text-align: center;
      }
      .popup-contact-form-input-box-textarea::placeholder{
        color: #7A7469;
      }

      .popup-contact-form-button {
        border-radius: 3rem;
        font-weight: 400;
        font-size: 30rem;
        color: #F2E0C3;
        width: 183rem;
        height: 46rem;
        border: 1rem solid #F2E0C3;
        font-family: 'ZumboCity', 'WangQiangShuFaTi';
        opacity: 0.5;
        background: transparent;
        cursor: pointer;
      }

      .popup-contact-form-button-click {
        color: #EFE1C6;
        border: 1rem solid #EFE1C6;
        opacity: 1;
      }

    }
    .popup-facebook-icon,
    .popup-youtube-icon {
      width: 64rem;
      height: 64rem;
      cursor: pointer;
    }
    .popup-facebook-icon{
      margin-left: 30rem;
    }
    .popup-url-icon-wrap {
      position: absolute;
      bottom: 96rem;
      right: 95rem;
      .popup-url-logo {
          height: 87rem;
          display: flex;
          cursor: pointer;
          align-items: flex-end;
          opacity: 0.8;
          span {
            height: 30rem;
            font-family: "ZumboCity", "WangQiangShuFaTi";
            font-weight: normal;
            font-size: 30rem;
            color: #EFE1C6;
            line-height: 30rem;
            letter-spacing: 1rem;
            text-align: center;
            font-style: normal;
          }

          .logoImg {
            width: 129rem;
            height: 101rem;
            margin-left: 18rem;
            background: url('../assets/img/popup_logo.png') no-repeat;
            background-size: 100% 100%;
          }
          .popup-url-logo-contactUS{
            width: 56rem;
            height: 56rem;
            margin-left: 31rem;
          }
      }

      .popup-url-logo:hover {
        opacity: 1;
      }
      .popup-url-logo:hover::after {
        content: "";
        /* 伪元素必须有 content，即使为空 */
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -26rem;
        /* 将图片显示在文字下方 */
        width: 80%;
        /* 图片宽度与链接文字宽度一致 */
        height: 19rem;
        /* 图片高度，可以根据需要调整 */
        background-image: url('../assets/icon/navLine.png');
        background-repeat: no-repeat;
        background-size: 100% 20rem;
        transition: all 0.2s ease;
      }
    }
  }

  .come-in-loading {
    z-index: 10;
    position: fixed;
    top: 0%;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    background-color: #D5C6B2;
    display: flex;
    justify-content: center;
    align-items: center;

    .image-sequence {
      position: relative;
      width: 600rem;
    }

    // .carousel-list{
    //   height: 500rem;
    //   img{
    //     width: 600rem;
    //     height: auto;
    //   }
    // }
    .image-gif {
      position: absolute;
      top: 0;
      left: 0;
      width: 600rem;
      height: auto;
      opacity: 0;
    }

    .loading-img {
      width: 600rem;
      height: auto;
    }

    .loading-img-gif {
      width: 400rem;
      height: auto;
    }

    .loading-video {
      width: 500rem;
      height: auto;
    }
  }
  .img-modal{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background: rgba(0, 0, 0, 0.8);
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 80vw;
      max-width: 1200rem;
      height: auto; /* 限制图片最大高度为视口高度的80% */
      cursor: default; /* 重置光标，防止点击图片时触发隐藏 */
    }
  }
}

@media screen and (max-width: 1366rem) {
  .home {
    .top {
      .layer-top-center-img {
        display: inline;
      }
    }
  }
}
</style>