import Vue from 'vue'
import App from './App.vue'
import store from './store'
import './styles/index.scss'
import './element/index'
import '@/assets/icons'
import './assets/font/font.css'
import { localCanhaiLanguageGet } from './utils'
import './utils/directive'

/*
 * 语言设置1是英文，2是中文
 * 1:判端是否在页面选择语言缓存了
 * 2:通过浏览器语言判断
 */
if (localCanhaiLanguageGet()) {
  store.commit('setLang', localCanhaiLanguageGet())
} else {
  if (navigator.language === 'zh' || navigator.language === 'zh-CN') {
    store.commit('setLang', '2')
  } else {
    store.commit('setLang', '1')
  }
}


Vue.config.productionTip = false
function isAndroidTablet(){
  let userAgent = navigator.userAgent || navigator.vendor || window.opera;
  let isAndroid = /android/i.test(userAgent.toLowerCase());
  let isMobile = /mobile/i.test(userAgent.toLowerCase());
  let isXiaomi = /xiaomi/i.test(userAgent.toLowerCase());
  // 排除安卓手机和iOS设备
  if (isMobile && isXiaomi ) {
    return true;
  }else if(isMobile &&isAndroid){
    return true;
  }else{
    return false;
  }
}

// rem计算
function setRemPc() {
  const num = window.innerWidth < 768 ? 375 : isAndroidTablet()?375:1920;
  const whdef = 1 / num;// 表示1920的设计图,使用1PX的默认值
  const bodyWidth = document.body.clientWidth>1920?1920: document.body.clientWidth;// 当前窗口的宽度
  const rem = bodyWidth * whdef;// 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
  document.getElementsByTagName('html')[0].style.fontSize = `${rem}px`;
}
new Vue({
  store,
  render: h => h(App),
  created() {
    // 实例创建完成后被立即调用rem换算方法,解决内容闪现问题
    setRemPc();
    window.addEventListener('resize', setRemPc);// 浏览器窗口大小改变时调用rem换算方法
  },
}).$mount('#app')
