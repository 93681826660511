<template>
  <div class="mobile-home">
    <img @click="popup" src="@/assets/icon/list_icon.png" class="list-icon-click" alt="">
    <div class="top-text-language-wrap">
      <span 
        @click="selectLanguage('1')"
        :class="['top-text-language-select-li-span', languageTypeActive === '1' ? 'top-text-language-select-li-span-active' : '']"
      >EN</span>
        <span style="opacity:0.5;">&nbsp;/&nbsp;</span>
      <span 
        @click="selectLanguage('2')"
        :class="['top-text-language-select-li-span-chinese', languageTypeActive !== '1' ? 'top-text-language-select-li-span-active' : '']"
      >中</span>
    </div>
    <section id="topID" class="top">
      <div class='hero'>

        <div class='layer-bg layer parallax' data-depth='0.20'>

        </div>
        <div class='layer-1 layer parallax' data-depth='0.50'>

          <img class="layer-top-center-img" src="@/assets/phoneImg/top_center_phone.png" alt="">
          <img class="layer-top-left-renson-img" src="@/assets/phoneImg/top_left_renson_phone.png" alt="">
        </div>
        <!-- <div class='layer parallax' data-depth='0.10'>
          <img class="layer-top01-img" src="@/assets/img/top01.gif" alt="">
        </div> -->
        <div class='layer parallax' data-depth='0.60'>
          <div v-if="languageTypeActive !== '1'" class="top-text-img-wrap">
            <div class="top-text-en-wrap">
              <img class="layer-logo-img" src="@/assets/phoneImg/top_logo_phone.png" alt="">
              <img class="layer-text-en-img" src="@/assets/phoneImg/top_text_en_phone.png" alt="">
            </div>
            <div class="layer-text-chinese">
              <div class="layer-text-chinese-title1">【动画短片】 </div>
              <div style="text-align:center;">
                <p class="layer-text-chinese-title2">得不了动画出品<br/>导演：西风</p>
              </div>
            </div>
          </div>
          <div v-else class="top-text-img-wrap-en">
            <div class="top-text-en-wrap-en">
              <img class="layer-text-en-img-1" src="@/assets/phoneImg/top_text_en_1_phone.png" alt="">
              <img class="layer-logo-img-1" src="@/assets/phoneImg/top_logo_1_phone.png" alt="">
              
            </div>
            <div class="layer-text-en">
              <div class="layer-text-chinese-title1-1">A theatrical animated short film</div>
              <div style="text-align:right;">
                <p class="layer-text-chinese-title2-1">Presented by Debris Studio<br/>&nbsp;&nbsp;Directed by Xi Feng</p>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class='layer parallax' data-depth='0.60'>
          <img class="layer-text-img" src="@/assets/phoneImg/top_text_phone.png" alt="">
        </div> -->
      </div>
    </section>
    <section id="textID" class="text">
      <img class="textID-top-bottom-img" src="@/assets/mobileImg/top_bottom.png" alt="">
      <div class="text-box">
        <p :class="['text-box-p', languageTypeActive == '1' ? 'mb69' : 'text-box-p-chinese']">
        </p>
        <p :class="['text-box-p', languageTypeActive == '1' ? 'mb6' : 'text-box-p-chinese']">
        </p>
        <p :class="['text-box-p text-box-p2',languageTypeActive == '1' ? 'mb24' : 'text-box-p-chinese']">
        </p>
        <p :class="['text-box-p text-box-p2', languageTypeActive == '1' ? 'mb24' : 'text-box-p-chinese']">
        </p>
        <p :class="['text-box-p text-box-p2', languageTypeActive == '1' ? 'mb24' : 'text-box-p-chinese']">
        </p>
        <div class="text-boxIcon">
          <p :class="['text-box-p text-box-p2', languageTypeActive == '1' ? '' : 'text-box-p-chinese']">
         </p>
          <div class="text-box-icon"></div>
        </div>
       
      </div>
    </section>
    <section id="OVAsID" class="OVAs">
      <div class="OVAs-top">
        <img class="OVAs-top-img move-right" loading="lazy" src="../assets/mobileImg/headstock-img.png">
        <img class="OVAs-top-img1 move-left" loading="lazy" src="../assets/mobileImg/tailstock-img.png">
      </div>
      <img class="grass-img move-right" loading="lazy" src="../assets/img/grass_img.png">
      <img class="ship-img move-left" loading="lazy" src="../assets/mobileImg/ship_img.png">
      <div class="OVAs-center">
        <div class="video-box">
          <!-- <div class="video-border"></div> -->
          <div class="video-err" v-if="videoError">
            <p>Video failed to load. Please check your network connection.</p>
            <div class="videoerr-ImgBtn" @click="reloadVideo">
              <img src="../assets/icon/refresh_icon.png" alt="">
              <span>Refresh</span>
            </div>
          </div>
          <div class="cover_bg" v-if="isPlaying">
            <img @click="togglePlay" src="../assets/mobileImg/play_btn.png" alt="">
          </div>
          <video class="video" @error="handleError" @play="handlePlay" @pause="handlePause" controls ref="videoRef"
            webkit-playsinlin="true" preload="metadata" loop="loop" :muted="isMuted"
            src="../assets/video/yr.mp4"></video>
          <!-- <img id="playPauseButton" v-if="isPlaying" class="play-btn" @click="togglePlay" src="../assets/img/play.png"
            alt=""> -->
          <!-- <button @click="toggleMute" class="muted-icon">{{ isMuted ? '开启声音' : '关闭声音' }}</button> -->
        </div>
      </div>
    </section>
    <section id="characterID" class="character">
      <div class="shade"></div>
      <div class="shade2"></div>
      <div class="character-title">
        <h2 class="text-box-h2 mb17">{{ languageTypeActive !== '1' ? '角色介绍' : 'CHARACTERS' }}</h2>
        <img src="../assets/icon/line_icon.png" alt="">
      </div>
      <div class="character-box">
        <div class="role-box" ref="roleBox">
          <ul class="role-box-ul">
            <li class="role-box-li">
              <div class="silhouette">
                <img src="../assets/img/laoge_img.png" alt="">
              </div>
              <span>{{ languageTypeActive !== '1' ? '葛' : 'Ge' }}</span>
            </li>
            <li class="role-box-li">
              <div class="silhouette silhouette2">
                <img src="../assets/img/xiaotangren_img.png" alt="">
              </div>
              <span class="pl15">{{ languageTypeActive !== '1' ? '唐' : 'Tang' }}</span>
            </li>
            <li class="role-box-li">
              <div class="silhouette silhouette3">
                <img src="../assets/img/tuoniao_img.png" alt="">
              </div>
              <span>{{ languageTypeActive !== '1' ? '呆鸟' : 'Dodo' }}</span>
            </li>
          </ul>
          <div class="role-box-describe" ref="boxDescribe">
            <div class="describe-centre" v-show="roleNum == 0">
              <div class="role-box-describe-bg"></div>
              <div class="describe-centre-btn"></div>
              <img class="skin_figure" src="../assets/mobileImg/laoge_img.png" alt="">
              <div :class="['introduce', languageTypeActive === '1' ? '' :'introduce-chinese']">
                <h2 class="introduce_text_name">{{ languageTypeActive !== '1' ? '葛' : 'Ge' }}</h2>
                <h4 class="introduce_text"> {{ languageTypeActive !== '1' ? ge_cn : ge_en }}</h4>
              </div>
            </div>
            <div class="describe-centre" v-show="roleNum == 1">
              <div class="role-box-describe-bg  describe-bg2"></div>
              <div class="describe-centre-btn"></div>
              <img class="skin_figure skin2" src="../assets/mobileImg/xiaotangren_img.png" alt="">
              <div :class="['introduce introduce2', languageTypeActive === '1' ? '' :'introduce-chinese']">
                <h2 class="introduce_text_name">{{ languageTypeActive !== '1' ? '唐' : 'Tang' }}</h2>
                <h4 class="introduce_text"> {{ languageTypeActive !== '1' ? tang_cn : tang_en }}</h4>
              </div>
            </div>
            <div class="describe-centre" v-show="roleNum == 2">
              <div class="role-box-describe-bg  describe-bg3"></div>
              <div class="describe-centre-btn"></div>
              <img class="skin_figure skin3 " src="../assets/mobileImg/tuoniao_img.png" alt="">
              <div :class="['introduce introduce3', languageTypeActive === '1' ? '' :'introduce-chinese']">
                <h2 class="introduce_text_name">{{ languageTypeActive !== '1' ? '呆鸟' : 'Dodo' }}</h2>
                <h4 class="introduce_text">{{ languageTypeActive !== '1' ? niao_cn : niao_en }}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="character-box-bottom"></div>
    </section>
    <section id="rollID" class="roll">
      <div class="rollID-height"></div>
      <div class="rollID-title">
        <h2 class="title-h2">{{ languageTypeActive !== '1' ? '概念设定' : 'CONCEPT ART' }}</h2>
        <img src="../assets/icon/rollID_title_icon.png" alt="">
      </div>
      <div class="roll-box">
        <ul class="roll-box-ul wrapper">
          <li class="roll-box-li"><img @click="showImage(rollImage1)" width='786rem' height="442rem" class="roll-box-img"
              :src="rollImage1" alt=""></li>
          <li class="roll-box-li"><img width='786rem' @click="showImage(rollImage6)" height="442rem" class="roll-box-img"
              :src="rollImage6" alt=""></li>
        </ul>
      </div>
      <div class="roll-box">
        <ul class="roll-box-ul wrapper">
          <li class="roll-box-li"><img @click="showImage(rollImage2)" width='786rem' height="786rem" class="roll-box-img"
              :src="rollImage2" alt=""></li>
          <li class="roll-box-li"><img @click="showImage(rollImage3)"  width='786rem' height="786rem" class="roll-box-img"
              :src="rollImage3" alt=""></li>
        </ul>
      </div>
      <div class="roll-box">
        <ul class="roll-box-ul wrapper">
          <li class="roll-box-li"><img @click="showImage(rollImage5)" width='786rem' height="786rem" class="roll-box-img"
              :src="rollImage5" alt=""></li>
          <li class="roll-box-li"><img @click="showImage(rollImage4)"  width='786rem' height="786rem" class="roll-box-img"
              :src="rollImage4" alt=""></li>
        </ul>
      </div>
    </section>
    <section :class="{'bottom':true, 'bottomCh':languageTypeActive !== '1'}">
      <div class="footer-box">
        <div class="footer-box-top">
          <div class="footer-box-topLeft" @click="contactShow">
            <P>{{ languageTypeActive !== '1' ? '联系我们' : 'CONTACT US' }}</P>
            <div class="topLeft-iconbox ml6" ref="iconbox">
              <img class="topLeft-icon" src="../assets/img/arrows_icon.png" alt="">
            </div>
          </div>
          <div class="footer-logo">
            <div  class="footer-left-logo" @click="goUrl">
              <img :class="{'mb5':languageTypeActive !== '1'}" src="../assets/img/logo_icon.png" alt="">
              <div class="footer_name">
                <span>{{ languageTypeActive !== '1' ? '得不了动画工作室' : 'Produced by Debris Studio' }}</span>
                <div class="topLeft-iconbox ml6" ref="iconbox">
                  <img class="topLeft-icon2" src="../assets/img/arrows_icon.png" alt="">
                </div>
              </div>
            </div>
            <!-- <div class="footer-box-bottomIcon">
              <img
                :src="footeryoutubeType ? require('../assets/img/youtube_icon.png') : require('../assets/icon/youtube_footer_icon.png')"
                @mouseover="footeryoutubeType = false" @mouseleave="footeryoutubeType = true" class="icon" alt="">
              <img
                :src="footerFacebookType ? require('../assets/img/facebook_icon.png') : require('../assets/icon/facebook_footer_icon.png')"
                @mouseover="footerFacebookType = false" @mouseleave="footerFacebookType = true" class="icon" alt="">
            </div> -->
          </div>
        </div>
        <img class="partition-img" src="../assets/mobileImg/Bottom_line_img.png" alt="">
        <p class="footer-bottom-p">Copyright © 2024 Debris Studio. All rights reserved.</p>
      </div>
    </section>
    <!-- ******弹窗放在最后****** -->
    <section class="popup-id popup">
      <div class="popup-nav">
        <div class="popup-nav-bg"></div>
        <div class="popup-nav-box">
          <ul class="popup-nav-ul flex-col">
            <li @click="ScrollToID('topID')"
              :class="['popup-nav-li', languageTypeActive === '1' ? 'mb-en' : `mb-chinese popup-nav-li-chinese`]">
              <span class="popup-nav-li-span">{{ languageTypeActive === '1' ? 'TOP' : '首页' }} </span>
            </li>
            <li @click="ScrollToID('textID')" :class="['popup-nav-li', languageTypeActive === '1' ? 'mb-en' : `mb-chinese popup-nav-li-chinese`]">
              <span class="popup-nav-li-span">{{languageTypeActive === '1'?'STORY':'故事介绍'}} </span>
            </li>
            <li @click="ScrollToID('OVAsID')" :class="['popup-nav-li', languageTypeActive === '1' ? 'mb-en' : `mb-chinese popup-nav-li-chinese`]">
              <span class="popup-nav-li-span">{{ languageTypeActive === '1' ? 'TEASER' : '样片' }} </span>
            </li>
            <li @click="ScrollToID('characterID')" :class="['popup-nav-li', languageTypeActive === '1' ? 'mb-en' : `mb-chinese popup-nav-li-chinese`]">
              <span class="popup-nav-li-span">{{languageTypeActive === '1'?'CHARACTERS':'角色介绍'}} </span>
            </li>
             <li @click="ScrollToID('rollID')" :class="['popup-nav-li', languageTypeActive === '1' ? 'mb-en' : `mb-chinese popup-nav-li-chinese`]">
              <span class="popup-nav-li-span">{{ languageTypeActive === '1' ? 'CONCEPT ART' : '概念设定' }} </span>
            </li>
            <li @click="contactShow"
              :class="['popup-nav-li', languageTypeActive === '1' ? 'mb-en' : `mb-chinese popup-nav-li-chinese`]">
              <span class="popup-nav-li-span">{{ languageTypeActive === '1' ? 'CONTACT US' : '联系我们' }}<img
                  class="popup-nav-li-span-contactUS" src="@/assets/icon/contactUS.png" alt=""> </span>
            </li>
            <!-- <li :class="['popup-nav-language-select-li']">
              <img class="popup-youtube-icon" @mouseover="hoverYoutubeImage" @mouseleave="normalYoutubeImage"
                :src="youtubeImage" alt="">
              <img class="popup-youtube-icon" @mouseover="hoverFacebookImage" @mouseleave="normalFacebookImage"
                :src="facebookImage" alt="">
            </li> -->
            <li class="popup-nav-language-select-li">
              <div class="popup-url-logo" @click="goUrl">
                <div class="logoImg"></div>
                <span>
                  {{ languageTypeActive !== '1' ? '得不了动画工作室' : 'Produced by Debris Studio' }}
                  <img class="popup-url-logo-contactUS" src="@/assets/icon/contactUS.png" alt="">
                </span>
                
                <!-- <img class="popup-url-logo-contactUS" src="@/assets/icon/contactUS.png" alt=""> -->
              </div>
            </li>
          </ul>
        </div>
        <!-- <div class="popup-url-icon-wrap">
          <img class="popup-youtube-icon" @mouseover="hoverYoutubeImage" @mouseleave="normalYoutubeImage"
            :src="youtubeImage" alt="">
          <img class="popup-youtube-icon" @mouseover="hoverFacebookImage" @mouseleave="normalFacebookImage"
            :src="facebookImage" alt="">
        </div> -->
      </div>
      <img @click="closePopup" class="el-icon-close-popup" src="@/assets/icon/popup_close.png" alt="">
    </section>
    <section class="popup-contact-id popup">
      <div class="popup-contact">
        <div class="popup-contact-box">
          <div class="popup-contact-form">
            <h3 class="popup-contact-box-title form-mb14">{{ languageTypeActive === '1' ? 'CONTACT US' : '联系我们' }}</h3>
            <p class="popup-contact-box-text form-mb14">{{ languageTypeActive === '1' ? 'We value your feedback. If you have any suggestions or comments, please fill out the form below to share with us.' :
              '我们重视您的反馈。如果您有任何建议或意见，请填写以下表格与我们分享：' }}
            </p>
            <div class="popup-contact-form-input-box form-mb28">
              <p class="popup-contact-form-input-box-p form-mb8">{{ languageTypeActive === '1' ? 'Name' : '名字' }}</p>
              <input v-model="name" maxlength="30" :class="['popup-contact-form-input-box-input', nameErrorMessage ? 'popup-contact-form-input-box-input-active' : '']" type="text"
              :placeholder="languageTypeActive === '1' ? 'Please enter your name' : '请输入您的名字'">
              <p v-show="nameErrorMessage" :class="['email-error-message',languageTypeActive === '1' ? '' : 'email-error-message-chinese']">* {{ nameErrorMessage }}</p>
            </div>
            <div class="popup-contact-form-input-box form-mb28">
              <p class="popup-contact-form-input-box-p form-mb8">{{ languageTypeActive === '1' ? 'Email' : '邮箱' }}</p>
              <input v-model="email" maxlength="50"
                :class="['popup-contact-form-input-box-input', emailErrorMessage ? 'popup-contact-form-input-box-input-active' : '']"
                type="text" :placeholder="languageTypeActive === '1' ? 'Please enter your email address' : '请输入您的邮箱地址'">
              <p v-show="emailErrorMessage" :class="['email-error-message',languageTypeActive === '1' ? '' : 'email-error-message-chinese']">* {{ emailErrorMessage }}</p>
            </div>
            <div class="popup-contact-form-input-box form-mb28 ">
              <p class="popup-contact-form-input-box-p form-mb8">{{ languageTypeActive === '1' ? 'Message' : '留言' }}</p>
              <textarea maxlength="500" v-model="describe" type="textarea" rows="4" :class="['popup-contact-form-input-box-textarea', descriptionErrorMessage ? 'popup-contact-form-input-box-input-active' : '']"
              :placeholder="languageTypeActive === '1' ? 'Please enter your message or inquiry here' : '请在此输入您的建议或反馈'"></textarea>
              <p v-show="descriptionErrorMessage" :class="['email-error-message',languageTypeActive === '1' ? '' : 'email-error-message-chinese']">* {{ descriptionErrorMessage }}</p>
            </div>
            <div class="popup-contact-form-button-wrap">
              <button v-preventReClick v-if="!popupBtn" @click="submit"
                class="popup-contact-form-button popup-contact-form-button-click">{{ languageTypeActive === '1' ?
                'SUBMIT' : '提交' }}</button>
              <button v-preventReClick v-else class="popup-contact-form-button">{{ languageTypeActive === '1' ? 'SUBMIT' : '提交'
                }}</button>
            </div>
          </div>
        </div>
      </div>
      <img @click="closePopupContact" class="el-icon-close-popup" src="@/assets/icon/popup_close.png" alt="">
    </section>
    <section v-if="loadingShow" class="come-in-loading">
      <!-- <img class="loading-img" src="@/assets/img/top_logo.png" alt=""> -->
      <!-- <img class="loading-img" src="@/assets/img/fristLoading.gif" alt=""> -->
      <!-- <img class="loading-img" src="@/assets/img/fristLoading.webp" alt=""> -->
      <img class="loading-img-new" src="@/assets/img/fristLoading_512_new.gif" alt="">
      <!-- <img class="loading-img" :src="gif" /> -->
      <!-- <video autoplay muted class="loading-video" src="@/assets/video/loading.mp4"></video> -->
      <!-- <video autoplay="autoplay" loop="loop" muted class="loading-video" src="@/assets/video/loading-play.mp4"></video> -->
    </section>
    <transition name="fade">
      <section class="img-modal" v-show="isImageVisible" @click="hideImage">
        <img class="" :src="visibleImage" alt="Visible Image" @click.stop />
      </section>
    </transition>
    
    <Message v-if="MessageShow" :text="MessageText" :type="MessageType" />
  </div>
</template>
<script>
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
// import ScrollToPlugin from 'gsap/ScrollToPlugin';
import { mapState, mapMutations } from 'vuex'
import { localCanhaiLanguageSet } from '../utils'
import Message from '@/components/Message'
export default {
  name: 'Home',
  components: {
    Message
  },
  data() {
    return {
      youtubeUrl: require('@/assets/icon/youtube_icon.png'),
      youtubeActiveUrl: require('@/assets/icon/youtube_icon_active.png'),
      youtubeImage: require('@/assets/icon/youtube_icon.png'),
      facebookUrl: require('@/assets/icon/facebook_icon.png'),
      facebookActiveUrl: require('@/assets/icon/facebook_icon_active.png'),
      facebookImage: require('@/assets/icon/facebook_icon.png'),

      rollImage1: require('@/assets/img/box_li_img1.png'),
      rollImage2: require('@/assets/img/box_li_img2.png'),
      rollImage3: require('@/assets/img/box_li_img3.png'),
      rollImage4: require('@/assets/img/box_li_img4.png'),
      rollImage5: require('@/assets/img/box_li_img5.png'),
      rollImage6: require('@/assets/img/box_li_img6.png'),
      // gif:require('@/assets/gkb/image1.png'),
      // imgs:[
			// 	require('@/assets/gkb/image1.png'),
      //   require('@/assets/gkb/image2.png'),
      //   require('@/assets/gkb/image3.png'),
      //   require('@/assets/gkb/image4.png'),
      //   require('@/assets/gkb/image5.png'),
      //   require('@/assets/gkb/image6.png'),
      //   require('@/assets/gkb/image7.png')
			// ],
      // imgs:[
			// 	'@/assets/gkb/image1.png',
      //   '@/assets/gkb/image2.png',
      //   '@/assets/gkb/image3.png',
      //   '@/assets/gkb/image4.png',
      //   '@/assets/gkb/image5.png',
      //   '@/assets/gkb/image6.png',
      //   '@/assets/gkb/image7.png'
			// ],
      // currentIndex:0,
      // timer: null,
      // timerId:null,
      // ***************
      loadingShow:true,//loading加载懂消失
      visibleImage:'',//点击框内图片展示的它自己图片
      isImageVisible: false,
      comeInLoadingShow:false,//判断comeInLoading是否已经执行了
      // ***********
      name: '',
      email: '',
      describe: '',
      emailError: false,
      emailErrorMessage: '',
      nameErrorMessage: '',
      descriptionErrorMessage: '',
      isPlaying: true,
      isMuted: true,
      navAndContact: true,
      footeryoutubeType: true,
      footerFacebookType: true,
      roleNum: 0,//角色介绍
      videoError: false,//视频加载失败
      ge_cn: "只身流落到华丽岛的人类，与本地女子结合生下了女儿唐。为追逐名利，醉心于发明“魔幻小玩意儿” ，抛弃唐离家出走。",
      ge_en: 'A human, stranded on “Island Splendor”, had a daughter named Tang with a local woman. Obsessed with fame and fortune, he abandoned Tang and left home to pursue his passion for inventing "magical gadgets."',//角色介绍
      tang_cn: '葛的女儿，母亲是当地人，因难产去世。自己又被父亲抛弃，独自在残骸的世界中长大。苦难的经历锻造了她的坚毅、狂野、执着和善良。在偶然得知了父亲的下落后，踏上了寻找父亲的旅程。',
      tang_en: "Ge's daughter, with a local woman as her mother, lost her mother to childbirth complications. She was then abandoned by her father and grew up alone in a world of wreckage. The hardships she endured forged her resilience, wildness, determination, and kindness. Upon accidentally learning her father's whereabouts, she embarked on a journey to find him.",
      niao_cn: '为了躲避肉食动物的追杀躲进唐的花园，与唐相识，伴随唐长大，是唐的伙伴和坐骑。通人性，比通常的鸵鸟高大。',
      niao_en: "To escape from predators, it hid in Tang's garden, where it met Tang and grew up alongside her. It became Tang's companion and mount. This creature, taller and more intelligent than typical ostriches, shared a deep bond with Tang.",
      popupBtn: false,
      MessageText: 'Submission failed. Please check your network configuration and try again later.',
      MessageShow: false,
      MessageType: '',
      textboxpEn: [
        { text: 'This short film tells the story of a young girl who, after her father leaves home, grows up alone in a world filled with wreckage. Years later, when she reunites with her father...' },
        { text: '***' },
        { text: `"This film is created by the director out of an obsession with the beauty of wreckage. It also serves as an exploration of the style and production methods for a future feature-length film.` },
        { text: ` Wreckage symbolizes the fragmentation of the broader real world, as well as the imperfections and mutations of personal spiritual homes. Within each person, there might be a wreckage that can be rebuilt, dismantled, or left to continue decaying. The pursuit of perfection in life often becomes a driving force for individuals.` },
        { text: `In the debris of love, the search for its rebuilding begins."` },
        { text: '— Xi Feng' },
      ],
      textboxpCh: [
        { text: '本片讲述一个幼小的女孩，在父亲离家出走后，独自在遍布残骸的环境中艰难成长。多年后，当她再次与父亲相遇…' },
        { text: '***' },
        { text: '“本片是导演基于对残骸之美的痴迷而创作，同时这部短片是对未来长片电影风格及制作模式的探索。残骸的世界，破碎的情感，但人类从不放弃对爱的追寻。' },
        { text: '残骸，象征着广义的现实世界的支离破碎，也印证着个人精神家园的残缺和变异。每个人心里都可能有一座或可重建、或可拆除、或可继续荒芜的残骸。但让人生完美却总是能够成为人生的一种动力。' },
        { text: '残骸的世界，破碎的情感，但人类从不放弃对爱的追寻。”' },
        { text: '——西风' },
      ]
    }
  },
  mounted() {
    this.initGsap()
    emailjs.init("lCvE3WOqbqiit3J3q");
  },
  computed: {
    ...mapState(['languageTypeActive']),
  },
  methods: {
    ...mapMutations({
      setLang: 'setLang'
    }),
    // 初始化动画库，然后触发每个动画的设置
    initGsap() {
      gsap.registerPlugin(ScrollTrigger);
      // this.createPanUpAnimation()
      // this.createMoreFontHighlightAnimation()
      // this.scrollingLeftRight()
      // this.translationAnimation()
      // this.roleAnimation()
      // this.topLeftIcon()
      // this.startRotation()
      this.timer = setTimeout(() => {
        if(!this.comeInLoadingShow){
          this.comeInLoading(); // 超时后也结束loading
        }
        if (this.timer) {
          window.clearInterval(this.timer);
          this.timer = null;
        }
      }, 10000);
      document.addEventListener('DOMContentLoaded', () => {
        if(!this.comeInLoadingShow){
          this.comeInLoading(); // 超时后也结束loading
        }
      });
      // this.timer = setInterval(() => {
      //   if (document.readyState === 'complete') {
      //     this.comeInLoading()
      //     window.clearInterval(this.timer)
      //   }
      // }, 1000)
    },

    //滚动导航栏图片跟背景逐渐平移
    createPanUpAnimation() {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: ".hero",
          start: "top top",
          end: "bottom top",
          scrub: true
        }
      });
      gsap.utils.toArray(".parallax").forEach(layer => {
        const depth = layer.dataset.depth;
        const movement = -(layer.offsetHeight * depth)
        tl.to(layer, { y: movement, ease: "none" }, 0)
      });
    },
    //多个框按导航栏顺序滚动文字逐渐高亮
    createMoreFontHighlightAnimation() {
  document.querySelectorAll('.text-box-p').forEach((text, index, array) => {
    gsap.killTweensOf(text.querySelectorAll('span'));
    let words = '';
    if (this.languageTypeActive == '1') {
      words = this.textboxpEn[index].text.split(" "); // 将文本拆分成单词
    } else {
      words = this.textboxpCh[index].text.split("");
    }
    text.innerHTML = ""; // 清空原始文本

    // 根据语言类型调整高亮位置
    let top, bottom;
    if (this.languageTypeActive == '1') { // 英文
      let step = text.clientHeight 
      top = 50 - index * step;
      bottom = top - step;
    } else { // 中文
      let lineHeight = 2; // 每行高度
      let lines = Math.ceil(text.clientHeight / lineHeight); // 计算文本行数
      top = 50 - index * lines; // 调整高亮位置
      bottom = top - lines;
    }

    // 为每个单词创建一个span并赋予初试颜色
    words.forEach((word, wordIndex) => {
      let span = document.createElement('span');
      span.textContent = word;
      text.appendChild(span);
        // 如果不是最后一个单词，添加一个空格
      if (wordIndex < words.length - 1) {
        text.appendChild(document.createTextNode(' '));
      }
    });

    // 创建每个span的动画
    gsap.fromTo(text.querySelectorAll('span'), {
      color: '#634F44',
      display: 'inline-block',
    }, {
      opacity: '1', // 高亮颜色 - 如Tomato色
      color: '#EFE1C6',
      stagger: 0.05, // 每个单词按顺序依次高亮
      ease: "none",
      scrollTrigger: {
        trigger: text, // 每个文本块都是其自身触发器
        start: `top ${top}%`, // 计算每个框在视口顶部的位置开始动画
        end: `bottom ${bottom}%`, // 计算每个框在视口底部的位置结束动画
        toggleActions: "restart none none reset",
        scrub: true // 平滑过渡动画效果
      }
    });
  });
},

    // 框内左右滚动
    scrollingLeftRight() {
      gsap.utils.toArray('.roll-box').forEach((section, index) => {
        const w = section.querySelector('.wrapper');
        const [x, xEnd] = (index % 2) ? ['100%', (w.scrollWidth - section.offsetWidth) * -1] : [w.scrollWidth * -1 , 0];
        gsap.fromTo(w, { x }, {
          x: xEnd,
          scrollTrigger: {
            trigger: '.roll-box',
            scrub: 0.5
          }
        });
      })
    },
    showImage(image){
      this.visibleImage = image;
      this.isImageVisible = true;
    },
    hideImage() {
      this.isImageVisible = false;
    },
    // 图片左右平移
    translationAnimation() {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            entry.target.style.transform = 'none';
          } else {
            entry.target.style.transform = 'translate(-40rem, 0)';
          }
        },
        {
          threshold: 0.1
        }
      );

      const elements = this.$el.querySelectorAll('.move-right');
      elements.forEach(element => observer.observe(element));

      const observer1 = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            entry.target.style.transform = 'none';
          } else {
            entry.target.style.transform = 'translate(40rem, 0)';
          }
        },
        {
          threshold: 0.1
        }
      );

      const elements1 = this.$el.querySelectorAll('.move-left');
      elements1.forEach(element => observer1.observe(element));
    },
    // 播放暂停按钮
    togglePlay() {
      let video = this.$refs.videoRef;
      if (video.paused) {
        video.play();

      } else {
        video.pause();

      }
    },
    // 静音按钮
    toggleMute() {
      this.isMuted = !this.isMuted;
    },
    // 播放
    handlePlay() {
      this.isPlaying = false;
    },
    // 暂停
    handlePause() {
      this.isPlaying = true;
    },
    // 错误
    handleError() {
      this.videoError = true;
    },
    // 重新加载视频
    reloadVideo() {
      this.videoError = false;
      this.$refs.videoRef.load();

    },
    // 角色介绍
    roleAnimation() {
      gsap.utils.toArray(".role-box-li").forEach((container, index) => {

        // 从当前 container 中选择 ".information" 元素
        let info = this.$refs.boxDescribe;
        gsap.set(info, { opacity: 0, display: 'none' });
        // 从当前 container 中选择 ".silhouette .cover" 元素
        // 创建一个新的 GSAP 时间线，初始状态为暂停
        let tl = gsap.timeline({ paused: true });
        // 然后立即将 silhouette 元素的透明度设置为 0
        tl.to('.role-box-li', { opacity: 0, ease: "power2.out", duration: 1 }, 0).to(info, { opacity: 1, display: 'block', ease: "power2.out", duration: 1, transformOrigin: "center" }, 0).to('.shade2', { opacity: 0, ease: "power2.out", duration: 1, transformOrigin: "center" }, 0);
        container.addEventListener("click", () => {
          this.roleNum = index
          tl.timeScale(1).play()
        });
        // 当鼠标离开 container 时，将时间线的速度设置为 3 并反向播放动画（也就是回到初始状态）
        info.addEventListener("click", () => tl.timeScale(1).reverse());
      });
    },
    topLeftIcon() {
      let Iconbox = document.querySelector('.footer-box-topLeft');
      let tl = gsap.timeline({ paused: true });
      tl.to('.topLeft-icon', { yPercent: -100, repeat: -1, duration: 2, }, 0);
      Iconbox.addEventListener("click", () => {
        gsap.set('.topLeft-icon', { yPercent: 100 });
        tl.timeScale(1).restart()
      });
      Iconbox.addEventListener("mouseleave", () => {
        gsap.set('.topLeft-icon', { yPercent: 0 });
        tl.kill()
      });
    },
    topLeftIcon2() {
      let Iconbox = document.querySelector('.footer-left-logo');
      let tl = gsap.timeline({ paused: true });
      tl.to('.topLeft-icon2', { yPercent: -100, repeat: -1, duration: 2, }, 0);
      Iconbox.addEventListener("mouseenter", () => {
        gsap.set('.topLeft-icon2', { yPercent: 100 });
        tl.timeScale(1).restart()
      });
      Iconbox.addEventListener("mouseleave", () => {
        gsap.set('.topLeft-icon2', { yPercent: 0 });
        tl.kill()
      });
    },
    // 弹窗popup，从上到下
    popup() {
      const modal = document.querySelector('.popup-id');
      gsap.to(modal, { top: 0, duration: 1, ease: "power4.inOut" })
    },
    // 关闭弹窗
    closePopup() {
      const modal = document.querySelector('.popup-id');
      gsap.to(modal, { top: "-100vh", duration: 1, ease: "power4.inOut" })
    },
    // 联系弹窗
    contactShow() {
      this.closePopup()
      setTimeout(() => {
        this.popupContact()
      }, 1000)
    },
    // 弹窗popup，从上到下
    popupContact() {
      const modal = document.querySelector('.popup-contact-id');
      gsap.to(modal, { top: 0, duration: 1, ease: "power4.inOut" })

    },
    // 关闭联系我们弹窗
    closePopupContact() {
      this.name= ''
      this.email=''
      this.describe=''
      const modal = document.querySelector('.popup-contact-id');
      gsap.to(modal, { top: "-100vh", duration: 1, ease: "power4.inOut"})

    },
    startRotation(){
      this.timerId = setInterval(() => {
        this.currentIndex = (this.currentIndex + 1) % this.imgs.length;
        this.gif = this.imgs[this.currentIndex];
      }, 100); // 每100毫秒更换一次图片
    },
    stopRotation() {
      clearInterval(this.timerId);
    },
    // 数据加载完成动画
    comeInLoading() {
      this.comeInLoadingShow = true
      // let animationFrameId = requestAnimationFrame(this.chImg)
      const modal = document.querySelector('.come-in-loading');
      // const modalcenter = document.querySelector('.loading-img');
      // const modalcenter = document.querySelector('.loading-img');
      const Loadingshow = gsap.timeline();
      Loadingshow.to(modal, { duration:3,onComplete:()=>{this.createMoreFontHighlightAnimation()}})
      // Loadingshow.to(modalcenter, { duration: 1.5, ease: "none", })
      Loadingshow.to(modal, {  x: '100vw', duration: 1,ease: "power4.in",onComplete: () => {
        // modalcenter.style.display = 'none';
        this.loadingShow = false
        // this.stopRotation()
        this.createPanUpAnimation()
        // this.createMoreFontHighlightAnimation()
        this.scrollingLeftRight()
        this.translationAnimation()
        this.roleAnimation()
        this.topLeftIcon()
        // this.topLeftIcon2()
      } })
    },
    
    // 滚动到指定ID
    ScrollToID(targetId) {
      this.closePopup()
      const targetElement = document.getElementById(targetId);
      setTimeout(() => {
        targetElement.scrollIntoView({
          behavior: 'smooth'
        });
      }, 500)
    },
    selectLanguage(val) {
      this.setLang(val)
      localCanhaiLanguageSet(val)
      this.createMoreFontHighlightAnimation()
    },
    hoverYoutubeImage() {
      this.youtubeImage = this.youtubeActiveUrl;
    },
    normalYoutubeImage() {
      this.youtubeImage = this.youtubeUrl;
    },
    hoverFacebookImage() {
      this.facebookImage = this.facebookActiveUrl;
    },
    normalFacebookImage() {
      this.facebookImage = this.facebookUrl;
    },
    submit() {
      if (!this.name) {
        this.nameErrorMessage = this.languageTypeActive === '1' ? 'Name cannot be empty' : '姓名不能为空'
        return
      } else {
        this.nameErrorMessage = ''
      }
      if (!this.email) {
        this.emailErrorMessage = this.languageTypeActive === '1' ? 'Email cannot be empty' : '邮箱不能为空'
        return
      } else {
        if (!(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email))) {
          this.emailErrorMessage = this.languageTypeActive === '1' ? 'Invalid email format' : '邮箱格式不正确'
          return
        } else {
          this.emailErrorMessage = ''
        }
      }
      if (!this.describe) {
        this.descriptionErrorMessage = this.languageTypeActive === '1' ? 'Message cannot be empty' : '留言不能为空'
        return
      } else {
        this.descriptionErrorMessage = ''
      }
      this.popupBtn = true
      emailjs.send("service_2pg3jeh", "template_do6k3io", {
        name: this.name,
        email: this.email,
        message: this.describe
      }).then(res => {
        this.name = ''
        this.email = ''
        this.describe = ''
        this.emailErrorMessage = ''
        this.MessageText = this.languageTypeActive === '1' ? 'Submission successful.Thank you for your feedback. We will get back to you as soon as possible.' : '提交成功，感谢您的反馈，我们会尽快与您联系。'
        this.MessageShow = true
        setTimeout(() => {
          this.MessageShow = false
          this.MessageType = 'success'
        }, 3000)
        this.popupBtn = false
      }, err => {
        this.MessageType = 'warning'
        this.MessageText = this.languageTypeActive === '1' ? 'Submission failed. Sorry, there was an error during submission. Please try again later.' : '提交失败，抱歉，提交过程中出现错误。请稍后重试。'
        this.MessageShow = true
        setTimeout(() => {
          this.MessageShow = false
        }, 3000)
        this.popupBtn = false
      })
    },
    // 跳转官网
    goUrl() {
      window.open('https://debrisanimation.com')
    }

  },
}
</script>

<style lang="scss" scoped>
.mobile-home {
  position: relative;
  overflow: hidden;

  .list-icon-click {
    position: fixed;
    top: 20rem;
    right: 20rem;
    font-size: 20rem;
    color: #fff;
    z-index: 9;
    width: 40rem;
    height: 40rem;
    min-width: 40rem;
    min-height: 40rem;
    cursor: pointer;
  }
  .top-text-language-wrap{
    position: absolute;
    top: 32rem;
    right: 80rem;
    font-size: 22rem;
    color: #EFE1C6;
    font-family: "ZumboCity", "WangQiangShuFaTi";
    z-index: 6;
    .top-text-language-select-li-span{
      opacity: 0.5;
      cursor: pointer;
    }
    .top-text-language-select-li-span-chinese {
      opacity: 0.5;
      font-size: 18rem;
      line-height: 22rem;
      display: inline-block;
      cursor: pointer;
    }

    .top-text-language-select-li-span-active {
      opacity: 1;
    }
  }
  .top {
    position: relative;
    background-color: #C9B69F;
    z-index: -1;

    .hero {
      height: 504rem;
      overflow: hidden;
      position: relative;
      margin: 0 auto;
    }
    .layer-top-bg-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: auto;
    }
    .layer-top-left-renson-img {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 115rem;
      max-width: 115rem;
      height: auto;
    }
    .layer-top-center-img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -6rem;
      width: 138rem;
      height: auto;
    }
    .layer-top01-img {
      position: absolute;
      top: 12%;
      left: 50%;
      transform: translateX(-50%);
      width: 1728rem;
      height: auto;
    }
    .top-text-img-wrap {
      position: absolute;
      top: 50%;
      transform: translateY(-39%) translateX(-18%);
      left: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .top-text-img-wrap-en{
      position: absolute;
      top: 50%;
      transform: translateY(-19%);
      right: 32rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .layer-logo-img {
      width: 85rem;
      max-width: 85rem;
      height: auto;
    }
    .layer-text-en-img {
      width: 94rem;
      height: auto;
    }
    .layer-text-en-img-1{
      width: 193rem;
      height: auto;
    }
    .layer-logo-img-1{
      width: 34rem;
      height: auto;
      margin-left: 10rem;
    }
    .top-text-en-wrap{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 53rem;
    }
    .top-text-en-wrap-en{
      display: flex;
      margin-bottom: 106rem;
    }
    .layer-text-en{
      width: 100%;
    }
    .layer-text-chinese-title1{
      font-size: 19rem;
      color: #EFE1C6;
      line-height: 21rem;
      text-shadow: 1rem 1rem 0 #28211C;
      text-align: center;
      font-style: normal;
      font-family: "ZumboCity", "WangQiangShuFaTi";
      margin-bottom: 5rem;
    }
    .layer-text-chinese-title1-1{
      font-size: 20rem;
      color: #EFE1C6;
      line-height: 22rem;
      text-shadow: 1rem 1rem 0 #28211C;
      text-align: right;
      font-family: "ZumboCity", "WangQiangShuFaTi";
    }
    .layer-text-chinese-title2{
      font-size: 13rem;
      color: #EFE1C6;
      line-height: 17rem;
      text-shadow: 1rem 1rem 0 #28211C;
      text-align: center;
      display: inline-block;
      font-family: "ZumboCity", "WangQiangShuFaTi";
    }
    .layer-text-chinese-title2-1{
      font-size: 16rem;
      color: #EFE1C6;
      line-height: 17rem;
      text-shadow: 1rem 1rem 0 #28211C;
      text-align: left;
      display: inline-block;
      font-family: "ZumboCity", "WangQiangShuFaTi";
    }
    .layer-text-img {
      width: 224rem;
      max-width: 224rem;
      height: auto;
    }
    .layer-top-bottom-img {
      position: absolute;
      bottom: -20rem;
      left: 0;
      width: 100%;
      height: auto;
    }

    .layer {
      background-position: bottom center;
      background-size: auto;
      background-repeat: no-repeat;
      width: 100%;
      height: 504rem;
      position: fixed;
      z-index: -1;
    }

    .layer-bg {
      background-image: url('../assets/phoneImg/top_bg_phone.png');
      background-size: cover;
      background-position: bottom center;
    }
  }

  .text {
    width: 100vw;
    position: relative;
    background: url('../assets/mobileImg/text_bg_img.png') no-repeat;
    background-size: 100% 100%;
    padding: 0 10.5%;

    .textID-top-bottom-img {
      position: absolute;
      top: -80rem;
      left: 0;
      width: 100vw;
      object-fit: cover;

    }

    .text-box {
      width: 100%;
      margin: 0 auto;
      text-align: center;
      padding: 49rem 0 68rem 0;
    }

    .no1 {
      margin-bottom: 32rem;
    }

    .text-box-h2 {

      span {
        display: inline-block;
        opacity: 1;
        /* 确保默认透明度为1 */
      }
    }
    .text-boxIcon{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .text-box-icon {
      width: 43rem;
      height: 51rem;
      margin-left:10rem;
      background: url('../assets/icon/textboxIcon.png') no-repeat;
      background-size: 100% 100%;
    }
    .text-box-p {
      font-family: "ZumboCity", "WangQiangShuFaTi";
      font-weight: normal;
      font-size: 28rem;
      line-height: 32rem;
      text-align: center;

      span {
        line-height: 32rem;
      }
    }
    .text-box-p2{
      font-size: 20rem;
      line-height: 20rem;
    }
    

    .text-box-p-chinese {
      font-size: 22rem;
      line-height: 35rem;

      span {
        line-height: 40rem;
      }
    }
    .text-box-p-chinese2{
      font-size: 18rem;
      line-height: 28rem;
    }
  }

  .popup {
    z-index: 10;
    position: fixed;
    top: -100vh;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
  
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url('../assets/phoneImg/popup_nav_bg_phone_bg.png');
    display: flex;
    justify-content: center;
    align-items: center;

    .el-icon-close-popup {
      position: absolute;
      top: 10rem;
      right: 10rem;
      font-size: 30rem;
      font-weight: 600;
      width: 40rem;
      height: 40rem;
      min-width: 40rem;
      min-height: 40rem;
      color: #000;
      cursor: pointer;
    }

    .popup-nav {
      position: relative;
      width: 88%;
      height: 88%;
      
    }

    // .popup-nav-bg{
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   right: 0;
    //   bottom: 0;
    //   background-repeat: no-repeat;
    //   background-size: 100% 100%;
    //   background-image: url('../assets/img/popup_nav_bg.png');
    // }
    .popup-nav-box {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-image: url('../assets/phoneImg/popup_nav_bg_phone.png');
      display: flex;
      align-items: center;
      overflow-y: auto;
      .popup-nav-ul {
        margin-left: 12%;
      }

      .popup-nav-li {
        font-size: 50rem;
        // line-height: 50rem;
        color: #EFE1C6;
        vertical-align: middle;
        cursor: pointer;
        font-family: 'ZumboCity', 'WangQiangShuFaTi';
        display: inline-block;
      }

      .popup-nav-li-chinese {
        font-size: 36rem;
        // line-height: 36rem;
      }

      .popup-nav-li-span {
        position: relative;
        display: inline-block;
      }

      .popup-nav-li-span:hover::after {
        content: "";
        /* 伪元素必须有 content，即使为空 */
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        /* 将图片显示在文字下方 */
        width: 90rem;
        /* 图片宽度与链接文字宽度一致 */
        height: auto;
        /* 图片高度，可以根据需要调整 */
        background: url('../assets/icon/navLine.png') no-repeat;
        transition: all 0.2s ease;
      }

      .popup-nav-li-span-contactUS {
        position: absolute;
        top: 50%;
        right: -42rem;
        transform: translateY(-50%);
        width: 32rem;
        height: 32rem;
      }

      .popup-nav-language-select-li {
        font-size: 20rem;
        // line-height: 26rem;
        color: #EFE1C6;
        font-family: 'ZumboCity', 'WangQiangShuFaTi';
        vertical-align: middle;
      }

      .popup-nav-language-select-li-span {
        opacity: 0.5;
        cursor: pointer;
      }

      .popup-nav-language-select-li-span-active {
        opacity: 1;
      }
      .mb-en{
        margin-bottom: 30rem;
      }
      .mb-chinese{
        margin-bottom: 30rem;
      }
      .popup-url-logo {
        // height: 87rem;
        // display: flex;
        // cursor: pointer;
        // flex-direction: column;
        margin-top: 40rem;
        span {
          position: relative;
          height: 20rem;
          font-family: "ZumboCity", "WangQiangShuFaTi";
          font-weight: normal;
          font-size: 20rem;
          color: #EFE1C6;
          line-height: 20rem;
          font-style: normal;
          display: inline;
        }

        .logoImg {

          width: 82rem;
          height: 65rem;
          background: url('../assets/img/popup_logo.png') no-repeat;
          background-size: 100% 100%;
          margin-bottom: 12rem;
        }
        .popup-url-logo-contactUS{
          position: absolute;
          top: -18rem;
          right: -36rem;
          width: 32rem;
          height: 32rem;
          margin-left: 10rem;
        }
      }
    }

    .popup-contact {
      display: flex;
      align-items: center;
      justify-content: center;
      // width: 100%;
      height: 100vh;
      overflow-y: auto;
      .popup-contact-box {
        position: relative;
        padding: 10rem 0;
      }

      .popup-contact-form {
        padding: 6rem 0;
        width: 78.6vw;
        margin: auto 0;
      }

      .popup-contact-box-title {
        font-size: 36rem;
        color: #EFE1C6;
        line-height: 1;
        font-family: 'ZumboCity', 'WangQiangShuFaTi';
        text-align: center;
      }

      .popup-contact-box-text {
        max-width: 78.6vw;
        font-size: 20rem;
        color: #EFE1C6;
        line-height: 1.2;
        font-family: 'ZumboCity', 'WangQiangShuFaTi';
        text-align: center;
        padding: 0 20rem;
      }

      .popup-contact-form-input-box {
        position: relative;
      }

      .form-mb28 {
        margin-bottom: 28rem;
      }

      .form-mb14 {
        margin-bottom: 14rem;
      }

      .form-mb8 {
        margin-bottom: 8rem;
      }

      .popup-contact-form-input-box-p {
        font-weight: 400;
        font-size: 20rem;
        color: #F2E0C3;
        line-height: 1;
        font-family: 'ZumboCity', 'WangQiangShuFaTi';
      }

      .popup-contact-form-input-box-input {
        background: rgba(93, 93, 93, 0.4);
        border-radius: 3rem;
        width: 78.6vw;
        height: 32rem;
        font-size: 20rem;
        line-height: 1;
        font-family: 'ZumboCity', 'WangQiangShuFaTi';
        padding: 0 9rem;
        border: 1rem solid rgba(93, 93, 93, 0.4);
        color: #F2E0C3;
      }

      .popup-contact-form-input-box-input:focus {
        border: 1rem solid #F2E0C3;
      }

      .popup-contact-form-input-box-input::placeholder {
        line-height: 1;
        color: #7A7469;
      }

      .popup-contact-form-input-box-input-active {
        border: 1rem solid #FB4343;
      }

      .email-error-message {
        font-size: 17rem;
        color: #FB4343;
        position: absolute;
        top: 110%;
        left: 0;
        font-family: 'ZumboCity', 'WangQiangShuFaTi';
      }
      .email-error-message-chinese{
         font-size: 14rem;
         line-height: 1.1;
      }
      .popup-contact-form-input-box-textarea::placeholder{
        color: #7A7469;
      }

      .popup-contact-form-input-box-textarea{
        background: rgba(93, 93, 93, 0.4);
        border-radius: 3rem;
        width: 78.6vw;
        font-size: 20rem;
        line-height: 1;
        color: #F2E0C3;
        font-family: 'ZumboCity', 'WangQiangShuFaTi';
        padding: 6rem 10rem;
        border: 1rem solid rgba(93, 93, 93, 0.4);
        resize: none;
      }

      .popup-contact-form-input-box-textarea:focus {
        border: 1rem solid #F2E0C3;
      }

      .popup-contact-form-button-wrap {
        text-align: center;
      }

      .popup-contact-form-button {
        font-weight: 400;
        font-size: 20rem;
        color: #F2E0C3;
        width: 142rem;
        height: 42rem;
        border: 1rem solid #F2E0C3;
        font-family: 'ZumboCity', 'WangQiangShuFaTi';
        opacity: 0.5;
        background: transparent;
        cursor: pointer;
        margin-top: 8rem;
        border-radius: 3rem;
      }

      .popup-contact-form-button-click {
        opacity: 1;
      }
    }
    .popup-facebook-icon,
    .popup-youtube-icon {
      width: 32rem;
      height: 32rem;
      cursor: pointer;
    }

    .popup-youtube-icon {
      margin-right: 16rem;
    }
    .popup-url-icon-wrap {
      position: absolute;
      bottom: 6%;
      left: 12%;

      .popup-facebook-icon,
      .popup-youtube-icon {
        width: 32rem;
        height: 32rem;
        cursor: pointer;
      }

      .popup-youtube-icon {
        margin-right: 16rem;
      }
    }
  }

  .come-in-loading {
    z-index: 10;
    position: fixed;
    top: 0%;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    background-color: #D5C6B2;
    display: flex;
    justify-content: center;
    align-items: center;

    .loading-img {
      width: 90%;
      height: auto;
    }
    .loading-img-new {
      width: 50%;
      height: auto;
    }
    .loading-video {
      width: 50%;
      height: auto;
    }
  }

  .OVAs {
    background: url("../assets/mobileImg/OVAs_bg_img.png") no-repeat;
    background-size: 100% 100%;
    position: relative;
    padding: 100rem 0 7rem 0;

    .OVAs-top {
      position: absolute;
      width: 100%;
      height: 141rem;
      top: -93rem;

      .OVAs-top-img {
        position: absolute;
        left: 0;
        height: 139rem;
        width: 113rem;
        transform: translate(-40rem, 0);
        transition: transform 2500ms ;
      }

      .OVAs-top-img1 {
        position: absolute;
        right: 0;
        height: 139rem;
        width: 80rem;
        transform: translate(40rem, 0);
        transition: transform 2500ms;
      }
    }


    .grass-img {
      position: absolute;
      top: 163rem;
      left: 0;
      height: 87rem;
      width: 80rem;
      transform: translate(-40rem, 0);
      transition: transform 2500ms ;
    }

    .ship-img {
      position: absolute;
      top: 282rem;
      right: 0;
      height: 50rem;
      width: 138rem;
      transform: translate(40rem, 0);
      transition: transform 2500ms ;
      z-index: 1;
    }

    .OVAs-center {
      width: 295rem;
      margin: 0 auto;

      .video-box {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .video-border {
          width: 100%;
          background: url('../assets/img/video_border.png') no-repeat;
          background-size: 100% 100%;
          height: 100%;
          position: absolute;
          z-index: 1;
        }

        .video-err {
          position: absolute;
          z-index: 2;

          p {
            font-family: "ZumboCity", "WangQiangShuFaTi";
            font-weight: normal;
            font-size: 19rem;
            color: #EFE1C6;
            line-height: 14rem;
            text-align: center;
            font-style: normal;
          }

          .videoerr-ImgBtn {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 16rem;

          }

          img {
            width: 20rem;
            object-fit: contain;
            margin-right: 8rem;
          }

          span {
            font-family: "ZumboCity", "WangQiangShuFaTi";
            font-weight: normal;
            font-size: 20rem;
            color: #EFE1C6;
            line-height: 14rem;
            text-align: right;
            font-style: normal;
          }
        }

        .cover_bg {
          position: absolute;
          z-index: 2;
          width: 100%;
          height: 100%;
          background: url('../assets/mobileImg/video_cover.png') no-repeat;
          background-size: 100% 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 42rem;
            height: 42rem;
          }
        }

        .video {
          width: calc(100%);
          height: calc(100%);
        }

        .play-btn {
          position: absolute;
          width: 100rem;
          height: 100rem;
          cursor: pointer;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        .muted-icon {
          position: absolute;
          bottom: 5%;
          right: 10%;
        }
      }
    }
  }

  .character {
    background: url('../assets/mobileImg/character_bg_img.png') no-repeat;
    background-size: 100% 100%;
    padding: 96rem 0 56rem 0;
    position: relative;

    .shade {
      position: absolute;
      top: 0;
      width: 100%;
      height: 244rem;
      background: url("../assets/mobileImg/shade_img.png") no-repeat;
      background-size: 100% 100%;
    }

    .shade2 {
      position: absolute;
      top: 203rem;
      width: 100%;
      height: 116rem;
      background: url("../assets/mobileImg/shade_img2.png") no-repeat;
      background-size: 100% 100%;
    }


    .character-title {

      h2 {
        font-family: "ZumboCity", "WangQiangShuFaTi";
        font-weight: normal;
        font-size: 32rem;
        color: #57453B;
        line-height: 24rem;
        letter-spacing: 1rem;
        text-align: center;
        font-style: normal;
        margin-bottom: 5rem;
      }

      img {
        display: block;
        height: 7rem;
        width: 70rem;
        margin: 0 auto;
      }
    }

    .character-box {
      width: 100%;
      text-align: center;
      position: relative;

      .role-box {
        width: 100%;
        position: relative;
        height: 100%;

        .role-box-describe {
          width: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        .describe-centre {
          width: 100%;
          height: 294rem;
          position: relative;
        }

        .role-box-describe-bg {
          width: 100%;
          height: 100%;
          position: absolute;
          background: url("../assets/mobileImg/floating_bg_icon.png") repeat;
          background-size: 100% 100%;
        }

        .describe-centre-btn {
          width: 24rem;
          height: 24rem;
          position: absolute;
          top: 54rem;
          right: 29rem;
          background: url("../assets/mobileImg/close_icon.png") repeat;
          background-size: 100% 100%;
        }

        .describe-bg2 {
          background: url("../assets/mobileImg/floating_bg2_icon.png") repeat;
          background-size: 100% 100%;
        }

        .describe-bg3 {
          background: url("../assets/mobileImg/floating_bg3_icon.png") repeat;
          background-size: 100% 100%;
        }

        .skin_figure {
          position: absolute;
          left: 9rem;
          top: 60rem;
          width: 142rem;
          object-fit: contain;
        }



        .skin2 {
          left: 69rem;
          top: 96rem;
          width: 53rem;
          object-fit: contain;
        }

        .skin3 {
          left: 19rem;
          top: 33rem;
          width: 171rem;
          object-fit: contain;
        }

        .introduce {
          position: absolute;
          bottom: 70rem;
          right: 33rem;
          width: 220rem;
          font-family: "ZumboCity", "WangQiangShuFaTi";
          font-weight: normal;
          text-shadow: 1rem 1rem 0rem #57453B;

          h2 {
            font-weight: normal;
            font-size: 18rem;
            color: #FFFFFF;
            line-height: 13rem;
            letter-spacing: 1rem;
            text-align: center;
            font-style: normal;
            margin-bottom: 6rem;
            text-shadow: 1rem 1rem 0rem #57453B;
          }

          h4 {
            font-weight: normal;
            font-size: 15rem;
            color: #FFFFFF;
            line-height: 14rem;
            text-align: center;
            font-style: normal;
            text-shadow: 1rem 1rem 0rem #57453B;
          }
        }
        .introduce-chinese{
          h2 {
            font-size: 16px;
            line-height: 17px;
          }

          h4 {
            font-size: 14px;
            line-height: 17px;
          }
        }

        .introduce2 {
          bottom: 27rem;
          right: 27rem;
        }

        .introduce3 {
          top: 158rem;
          right: 32rem;
        }
      }

      .role-box-ul {
        width: 100%;
        padding: 12rem 35rem 40rem 50rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .role-box-li {
          .silhouette {
            width: 59rem;
            margin-bottom: 11rem;

            img {
              width: 100%;
              object-fit: contain;
            }
          }

          .silhouette2 {
            width: 86rem;
          }

          .silhouette3 {
            width: 77rem;
          }

          span {
            font-family: "ZumboCity", "WangQiangShuFaTi";
            font-weight: normal;
            font-size: 22rem;
            color: #57453B;
            line-height: 17rem;
            text-align: center;
            font-style: normal;
          }
        }
      }


    }

    .character-box-bottom {
      position: absolute;
      bottom: -1rem;
      width: 100%;
      height: 86rem;
      background: url("../assets/mobileImg/top_bottom.png") no-repeat;
      background-size: 100% 100%;
    }
  }

  .roll {
    background: url('../assets/mobileImg/atmosphere_img.png') no-repeat;
    background-size: 100% 100%;
    height: 260rem;
    margin: -1rem 0;

    .rollID-height {
      height: 10rem;
    }
    .rollID-title{
      margin-bottom: 30rem;
      .title-h2{
        font-family: "ZumboCity", "WangQiangShuFaTi";
        font-weight: normal;
        font-size: 28rem;
        color: #D4C3AF;
        line-height: 31rem;
        text-align: center;
        font-style: normal;
        margin-bottom: 5rem;
      }
      img{
        display: block;
        width: 90rem;
        height:8rem;
        margin: 0 auto;
      }
    }

    .roll-box {
      padding-bottom: 18rem;
    }

    .roll-box-ul {
      display: flex;
    }

    .roll-box-li {
      margin-right: 6rem;
    }

    .roll-box-img {
      width: 238rem;
      height: 134rem;
    }
  }
  .bottom {
    height: 594rem;
    background: url('../assets/mobileImg/Bot_back.png') no-repeat;
    background-size: 100% 100%;
    padding-top: 428rem;
    padding-bottom: env(safe-area-inset-bottom);

    .footer-box {
      width: 335rem;
      margin: 0 auto;

      .footer-box-top {
        margin-bottom: 18rem;

        .footer-box-topLeft {
          display: flex;
          align-items: center;
          margin-bottom: 15rem;

          p {
            font-family: "ZumboCity", "WangQiangShuFaTi";
            font-weight: normal;
            font-size: 32rem;
            color: #57453B;
            letter-spacing: 1rem;
            text-align: left;
            font-style: normal;
          }
          .topLeft-iconbox {
            width: 20rem;
            overflow: hidden;
            img {
              width: 100%;
              object-fit: contain;
            }
          }
        }
        

        .footer-logo {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;

          .footer-left-logo {
            cursor: pointer;
          }

          .footer_name {
            display: flex;
            align-items:flex-end;
            font-family: "ZumboCity", "WangQiangShuFaTi";
            font-weight: normal;
            font-size: 16rem;
            color: #57453B;
            text-align: left;
            font-style: normal;
            .topLeft-iconbox {
              width: 20rem;
              overflow: hidden;

              img {
                width: 100%;
                object-fit: contain;
                margin-bottom: 0rem;
              }

            }
          }

          img {
            width: 52rem;
            object-fit: contain;
          }

          .footer-box-bottomIcon {
            position: relative;
            bottom: -5rem;
            display: flex;

            .icon {
              width: 28rem;
              height: 28rem;
              margin-right: 16rem;
            }
          }
        }
      }

      .partition-img {
        width: 100%;
        height: 4rem;
      }

      .footer-bottom-p {
        margin-top: 13rem;
        font-family: "ZumboCity", "WangQiangShuFaTi";
        font-weight: normal;
        font-size: 14rem;
        color: #57453B;
        line-height: 10rem;
        font-style: normal;
      }
    }
  }
  .bottomCh{
    padding-top: 418rem;
  }
  .img-modal{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background: rgba(0, 0, 0, 0.8);
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 90vw;
      height: auto; /* 限制图片最大高度为视口高度的80% */
      cursor: default; /* 重置光标，防止点击图片时触发隐藏 */
    }
  }
}
@media screen and (orientation: landscape) {
  /* 在这里添加针对横屏模式的样式 */
  .mobile-home {
    .popup {
      .popup-nav-box {

        display:block;
        padding: 20rem 0;
        .popup-nav-ul {
          margin-left: 12%;
        }
      }

      .popup-contact {
        overflow-y: auto;
        .popup-contact-box {
          position: relative;
          padding: 10rem 0;
        }

        .popup-contact-form {
          padding: 6rem 0;
          width: 78.6vw;
          margin: auto 0;
        }
      }
    }
  }
}
</style>