import Vue from 'vue'
/**
 *
 * 自定义的一些指令
 * preventReClick：防止多次点击。
 *
 *
 * clickoutside: 点击其他区域关闭当前的model展示
 *
 */
Vue.directive('preventReClick', {
  inserted: function (el, binding) {
    // console.log(el.disabled)
    el.addEventListener('click', () => {
      if (!el.disabled) {
        el.disabled = true
        setTimeout(() => {
          el.disabled = false
        }, binding.value || 5000)
        //binding.value可以自行设置。如果设置了则跟着设置的时间走
        //例如：v-preventReClick='500'
      }
    })
  },
})
