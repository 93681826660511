

// ************************缓存语言**********************
const canhaiLanguage = 'CANHAI_LANGUAGE'
// localStorage的修改
export function localCanhaiLanguageSet(value) {
  localStorage.setItem(canhaiLanguage, JSON.stringify(value))
}
// localStorage的获取值
export function localCanhaiLanguageGet() {
  return JSON.parse(localStorage.getItem(canhaiLanguage))
}
// localStorage的删除
export function localCanhaiLanguageRemove() {
  localStorage.removeItem(canhaiLanguage)
}
