<template>
  <div class="mssage_box">
    <div class="left-icon">
      <img v-if="type=='warning'" src="../../assets/icon/plaint_icon.png" alt="">
      <img v-else src="../../assets/icon/right_icon.png" alt="">
    </div>
    <p>
      {{ text }}
    </p>
  </div>
</template>

<script>

export default {
  name: 'Message',
  props: {
    text: {
      type: String,
      default: 'Submission failed. Please check your network configuration and try again later.'
    },
    type:{
      type: String,
      default: 'success',//success/warning/info/error
    }
  },
  computed: {

  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.mssage_box {
  position: fixed;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  max-width: 80vw;
  background: #CEBBA1;
  border-radius: 3px;
  padding: 13rem 18rem;
  display: flex;

  .left-icon {
    margin-right: 16rem;

    img {
      width: 30rem;
      height: 30rem;
    }
  }

  p {
    width: 424px;
    font-family: "ZumboCity", "WangQiangShuFaTi";
    font-weight: normal;
    font-size: 30px;
    color: #282824;
    line-height: 30px;
    text-align: left;
    font-style: normal;
  }
}
</style>
